<!--
    画面名：ログイン画面
-->
<template>
  <v-app class="grey lighten-3">
    <CallApi ref="CallApi" />
    <!-- ダイアログ定義 -->
    <dialogParts ref="dialogParts" />
    <v-container fluid style="padding-top: 100px; min-width: 1500px">
      <v-row justify="center">
        <v-col cols="5" style="min-width: 800px; max-width: 800px">
          <v-row class="" justify="center">
            <form>
              <v-card
                width="780px"
                height="480px"
                class="mx-auto bg-grey-lighten-4"
                style="border: thin solid #1c84ac"
              >
                <v-card-title class="mt-10">
                  <h1
                    class="display-1 font-weight-black mx-auto px-8 mb-n2 mt-2"
                  >
                    POSデータクレンジングサービス
                  </h1>
                </v-card-title>

                <v-card-text class="pt-3">
                  <v-row>
                    <v-col>
                      <v-text-field
                        class="title py-8 mx-8"
                        color="#1C84AC"
                        label="メールアドレス"
                        outlined
                        prepend-inner-icon="mdi-email"
                        v-model="username"
                        name="username"
                        maxlength="320"
                      />
                      <v-text-field
                        class="title mt-n5 mx-8"
                        color="#1C84AC"
                        label="パスワード"
                        outlined
                        type="password"
                        prepend-inner-icon="mdi-lock"
                        v-model="password"
                        name="password"
                        maxlength="100"
                      />
                      <v-row class="mx-7 mt-3">
                        <v-btn
                          block
                          height="50"
                          dark
                          color="#1C84AC"
                          size="x-large"
                          @click="login_Click"
                        >
                          <h3>ログイン</h3>
                        </v-btn>
                      </v-row>
                      <v-row justify="center" class="mt-10">
                        <v-btn
                          text
                          class="text-decoration-underline"
                          @click="reset_Click"
                          color="#1C84AC"
                          >パスワードリセット
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </form>
          </v-row>
          <v-row class="" justify="center">
            <v-card
              width="780px"
              height="150px"
              class="mx-auto mt-2 lighten-4"
              color=""
              style="border: thin solid #1c84ac"
            >
              <v-card-text class="blue-grey--text" v-html="inquiry">
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>

        <!-- お知らせ -->
        <v-col cols="4" style="min-width: 650px; max-width: 650px">
          <v-row class="" justify="center">
            <v-card
              width="100%"
              height="640px"
              class="pl-1"
              style="border: thin solid #1c84ac"
            >
              <!-- お知らせの中身 -->
              <v-card-text
                class="blue-grey--text pt-0 mt-4"
                :style="{ 'overflow-y': 'auto', height: '90%' }"
              >
                <div
                  class="sticky-header white"
                  style="position: sticky; top: 0"
                >
                  <v-row class="ml-1 mb-2" no-gutters>
                    <h2 class="">Infomation</h2>
                    <v-icon color="blue-grey" class="ml-2">mdi-bell</v-icon>
                  </v-row>
                  <v-divider class="mt-3" />
                </div>

                <div v-for="(news, index) in news_list" :key="index">
                  <v-card-title class="">
                    <v-row>
                      <v-col cols="4">
                        <span style="font-size: 18px">{{ news.start_at }}</span>
                      </v-col>
                      <v-col cols="8">{{ news.subject }} </v-col>
                    </v-row>
                  </v-card-title>
                  <v-row>
                    <v-col cols="1" class="" />
                    <v-col cols="3" class="">
                      <v-row class="mt-0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              elevation="2"
                              style="
                                border: thin solid #1c84ac;
                                height: 35px;
                                width: 35px;
                              "
                              v-if="news.link_uri"
                              @click="openLink(news)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="25" color="#1C84AC">
                                mdi-link-variant
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>リンクを開く</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              small
                              class="ml-2"
                              elevation="2"
                              style="
                                border: thin solid #1c84ac;
                                height: 35px;
                                width: 35px;
                              "
                              v-if="news.file_nm"
                              @click="downloadFile(news)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="25" color="#1C84AC">
                                mdi-paperclip
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>添付ファイルダウンロード</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                    <v-col cols="8">
                      <span
                        class=""
                        style="white-space: pre-wrap"
                        v-html="news.body"
                      >
                      </span>
                    </v-col>
                  </v-row>

                  <v-divider class="mt-5" />
                </div>
              </v-card-text>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
      <div style="height: 20px" />
      <CopyRight />
      <div style="text-align: center; margin-top: 20px">
        <a href="/personalinfo.html" target="_blank">個人情報保護方針</a>
      </div>
    </v-container>
    <v-dialog v-model="$route.query.proxy" max-width="600" persistent>
      <v-card>
        <v-card-text>
          <v-row justify="center" class="pt-13 mb-2">
            <v-icon x-large color="">mdi-gamepad-circle-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <div class="text-h5 px-12 py-6">しばらくお待ち下さい...</div>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-progress-linear
            indeterminate
            striped
            height="10"
            color="grey"
          ></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CopyRight from '../../components/CopyRight.vue';
import CallApi from '../../components/CallApi.vue';
import dialogParts from './DialogParts.vue';

export default {
  components: {
    CallApi,
    CopyRight,
    dialogParts,
  },
  data: () => ({
    username: '',
    password: '',
    inquiry: '',
    news_list: [],
    file_base64: '',
  }),
  created() {
    // 代理ログイン用のハンドラ登録
    if (this.$route.query.proxy !== undefined) {
      this.addHandler();
    }
  },
  mounted() {
    // 初期情報取得
    this.$refs.CallApi.callApi(
      '/api/u-01/get_initial_info',
      {},
      (response) => {
        this.inquiry = response.data.inquiry;
        this.news_list = response.data.news_list.map((newsItem) => ({
          ...newsItem,
          start_at: this.formatDatetime(newsItem.start_at),
          stop_at: this.formatDatetime(newsItem.stop_at),
        }));
      },
      () => {
      // エラーハンドリングは共通処理
      },
    );
    if (this.$route.query.token !== undefined) {
      this.$refs.dialogParts.passwordReset(this.$route.query.token);
    }
  },
  destroyed() {
    // 代理ログイン用のハンドラ削除
    if (this.$route.query.proxy !== undefined) {
      this.removeHandler();
    }
  },
  methods: {
    // ログイン
    login_Click() {
      this.$refs.CallApi.callApi('/api/u-01/login', {
        username: this.username,
        password: this.password,
      }, (response) => {
        localStorage.accessToken = response.data.accessToken;
        localStorage.refreshToken = response.data.refreshToken;
        this.$router.push({ name: 'u-02' });
      }, () => {
        // エラーハンドリングは共通処理
      });
    },
    // パスワードリセットダイアログを開く
    reset_Click() {
      this.$refs.dialogParts.open();
    },

    openLink(news) {
      // リンクを開く
      window.open(news.link_uri, '_blank');
    },

    // 添付ファイルダウンロードボタン押下時
    async downloadFile(news) {
      this.$refs.CallApi.callApi(
        'api/u-01/download',
        {
          id: news.id,
        },
        (response) => {
          this.file_base64 = response.data.file_base64;
          this.start_download(news);
        },
        () => {
          // APIでエラーが発生した場合
        },
      );
    },
    async start_download(news) {
      // base64データをBlobオブジェクトに変換する
      const byteCharacters = atob(this.file_base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });

      // BlobオブジェクトをURLに変換する
      const url = URL.createObjectURL(blob);

      // ダウンロードリンクを作成する
      const link = document.createElement('a');
      link.href = url;
      link.download = news.file_nm; // ファイル名を指定する
      document.body.appendChild(link);

      // ダウンロードを開始する
      link.click();

      // 不要になったURLオブジェクトを解放する
      URL.revokeObjectURL(url);
    },

    // datetimeを文字列に変換する
    formatDatetime(datetime) {
      const date = new Date(datetime);
      return date.toLocaleString('ja-JP', {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false,
      });
    },

    // 代理ログインメッセージ受信ハンドラ登録
    addHandler() {
      window.addEventListener('message', this.messageHandler);
    },
    // 代理ログインメッセージ受信ハンドラ削除
    removeHandler() {
      window.removeEventListener('message', this.messageHandler);
    },
    // 代理ログインメッセージ受信ハンドラ
    messageHandler(event) {
      const { data } = event;
      if (data.accessToken !== undefined) {
        localStorage.accessToken = data.accessToken;
        localStorage.refreshToken = data.refreshToken;
        this.$router.push({ name: 'u-02' });
      }
    },
  },
};
</script>
