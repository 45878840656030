<!--
    画面名：ユーザー管理画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <!-- 検索欄に埋め込む部分 -->
    <v-row class="my-8">
      <v-row align="start" no-gutters>
        <v-col cols="2" class="mt-3">
          <h3>メールアドレス</h3>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="login_username"
            color="#1C84AC"
            label=""
            outlined
            background-color="white"
            prepend-inner-icon="mdi-email"
            maxlength="320"
            @input="updateValue"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mt-n12" align="center" justify="left">
      <v-row align="start" no-gutters>
        <v-col cols="2" class="mt-3"> <h3>ユーザー名</h3> </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="user_nm"
            color="#1C84AC"
            label=""
            outlined
            background-color="white"
            prepend-inner-icon="mdi-account"
            maxlength="30"
            @input="updateValue"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mt-n4" align="center" justify="left">
      <v-row align="start" no-gutters>
        <v-col cols="2" class="mt-3"> <h3>権限</h3> </v-col>
        <v-col cols="3">
          <v-select
            v-model="selected_role"
            :items="role_list_copy"
            item-text="role_nm"
            item-value="id"
            return-object
            color="#1C84AC"
            outlined
            background-color="white"
            prepend-inner-icon="mdi-security"
            @input="updateValue"
          />
        </v-col>
      </v-row>
    </v-row>

    <v-row class="mt-n4" align="center" justify="left">
      <v-row align="start" no-gutters>
        <v-col cols="2" class="mt-3">
          <h3>使用可能レイアウト</h3>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model="input_layout_nm"
            color="#1C84AC"
            label=""
            outlined
            background-color="white"
            prepend-inner-icon="mdi-card"
            @input="updateValue"
            maxlength="50"
          />
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="1" class="">
          <v-btn
            outlined
            elevation="2"
            height="60"
            width="130"
            color="#1C84AC"
            dark
            @click="search_Click"
          >
            <v-icon size="30">mdi-magnify</v-icon>
            <h3>検索</h3>
          </v-btn>
        </v-col>
        <v-col cols="1 offset=1"></v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';

export default {
  components: {
    CallApi,
  },
  props: {
    role_list: {
      type: Array,
      required: true,
    },
  },
  // 初期画面出力
  mounted() {
    // 選択肢がある項目は先頭に空白の選択肢を挿入する
    this.role_list_copy = [{ id: null, role_nm: '' }, ...this.role_list];
  },
  data: () => ({
    role_list_copy: [],
    login_username: '',
    user_nm: '',
    selected_role: {},
    input_layout_nm: '',
    search_list: [],
  }),
  methods: {
    updateValue() {
      this.search_list.push(this.login_username);
      this.search_list.push(this.user_nm);
      this.search_list.push(this.selected_role.id);
      this.search_list.push(this.input_layout_nm);
      this.$emit('InputChange', this.search_list);
      this.search_list = [];
    },
    search_Click() {
      this.$emit('search');
    },
  },
};
</script>
