<!--
    画面ID：DialogParts
    画面名：u-01 ダイアログ部分
-->
<template>
  <div>
    <CallApi ref="CallApi" />
    <!-- パスワードリセットダイアログ -->
    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      width="600"
      v-model="dlg_send"
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark>
          <v-icon>mdi-lock-reset</v-icon>
          <div class="text-h6 pa-5">パスワードリセット</div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 py-12 px-10">
            パスワードをリセットします。<br />登録されているメールアドレスを入力してください。
          </div>
          <v-text-field
            class="title px-10"
            color="#1C84AC"
            label="メールアドレス"
            outlined
            prepend-inner-icon="mdi-email"
            v-model="model_send.username"
            maxlength="320"
          />
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-btn
            color="blue-grey lighten-1"
            large
            dark
            class="mx-auto mr-2"
            width="30%"
            height="50"
            @click="dlg_send = false"
          >
            <h3>キャンセル</h3>
          </v-btn>

          <v-btn
            color="#1C84AC"
            large
            dark
            class="mx-auto ml-2"
            height="50"
            @click="send_Click"
          >
            <h3>パスワードリセット用メール送信</h3>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- メール送信完了ダイアログ -->
    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      width="600"
      v-model="dlg_send_done"
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark>
          <v-icon>mdi-lock-reset</v-icon>
          <div class="text-h6 pa-5">パスワードリセット</div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">
            パスワードリセット用のメールを<br />送信しました。<br /><br />
            メールの内容をご確認ください。
          </div>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row justify="center" class="mx-8">
            <v-btn
              color="blue-grey lighten-1"
              large
              dark
              class="mx-auto mr-2"
              width="30%"
              height="50"
              @click="dlg_send_done = false"
            >
              <h3>閉じる</h3>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 新しいパスワード入力ダイアログ -->
    <v-dialog
      persistent
      transition="dialog-bottom-transition"
      width="600"
      v-model="dlg_reset"
    >
      <v-card height="600">
        <v-toolbar color="grey darken-2" dark>
          <v-icon>mdi-lock-reset</v-icon>
          <div class="text-h6 pa-5">パスワードリセット</div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">新しいパスワードを設定してください。</div>
          <v-text-field
            class="title mx-8"
            color="#1C84AC"
            label="メールアドレス"
            outlined
            prepend-inner-icon="mdi-email"
            v-model="model_reset.username"
            disabled
          />
          <v-text-field
            class="title mx-8"
            color="#1C84AC"
            label="新しいパスワード"
            outlined
            type="password"
            prepend-inner-icon="mdi-lock-outline"
            v-model="model_reset.password"
            maxlength="100"
          />
          <v-text-field
            class="title mx-8"
            color="#1C84AC"
            label="新しいパスワード（確認用）"
            outlined
            type="password"
            prepend-inner-icon="mdi-lock-check-outline"
            v-model="model_reset.password_confirm"
            maxlength="100"
          />
          <v-row justify="center">
            <h4 class="red--text mx-8">
              パスワードは必ず英字、数字、記号を含め8文字以上で登録<br />
              ※使用可能な記号：{{
                "! @ # $ % ^ & * ( ) _ + ~ ` | } { [ ] : ; ? > < , . / -="
              }}
            </h4>
          </v-row>
        </v-card-text>
        <v-row justify="center" class="mx-8 mt-5">
          <v-btn
            color="blue-grey lighten-1"
            large
            dark
            class="mx-auto mr-2"
            width="45%"
            height="50"
            @click="dlg_reset = false"
          >
            <h3>キャンセル</h3>
          </v-btn>

          <v-btn
            color="#1C84AC"
            large
            dark
            class="mx-auto ml-2"
            width="45%"
            height="50"
            @click="reset_Click"
          >
            <h3>パスワード再設定</h3>
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- パスワード完了ダイアログ -->
    <v-dialog
      persistent
      v-model="dlg_reset_done"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark>
          <v-icon>mdi-lock-reset</v-icon>
          <div class="text-h6 pa-5">パスワードリセット</div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">パスワードが変更されました。</div>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row justify="center" class="mx-8">
            <v-btn
              color="blue-grey lighten-1"
              large
              dark
              class="mx-auto mr-2"
              width="30%"
              height="50"
              @click="dlg_reset_done = false; dlg_reset = false;"
            >
              <h3>閉じる</h3>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CallApi from '../../components/CallApi.vue';

export default {
  components: {
    CallApi,
  },
  data: () => ({
    // メール送信ダイアログ
    dlg_send: false,
    model_send: {
      username: '',
    },
    // メール送信完了ダイアログ
    dlg_send_done: false,
    // パスワードリセットダイアログ
    dlg_reset: false,
    model_reset: {
      username: '',
      password: '',
      password_confirm: '',
      token: '',
    },
    // パスワードリセット完了ダイアログ
    dlg_reset_done: false,
  }),
  methods: {
    open() {
      this.model_send.username = '';
      this.dlg_send = true;
    },
    passwordReset(token) {
      this.$refs.CallApi.callApi('/api/u-01/get_mail_address', {
        token,
      }, (response) => {
        this.model_reset.token = token;
        this.model_reset.username = response.data.username;
        this.dlg_reset = true;
      }, () => {
        // エラーハンドリングは共通処理
      });
    },
    send_Click() {
      this.$refs.CallApi.callApi('/api/u-01/send_password_reset_mail', {
        username: this.model_send.username,
      }, () => {
        this.dlg_send = false;
        this.dlg_send_done = true;
      }, () => {
        // エラーハンドリングは共通処理
      });
    },
    reset_Click() {
      this.$refs.CallApi.callApi('/api/u-01/password_reset', {
        username: this.model_reset.username,
        token: this.model_reset.token,
        password: this.model_reset.password,
        password_confirm: this.model_reset.password_confirm,
      }, () => {
        this.dlg_reset_done = true;
      }, () => {
        // エラーハンドリングは共通処理
      });
    },
  },
};
</script>
