<!--
    メイン画面（トップ画面以降の画面）のユーザー側テンプレート
-->
<template>
  <v-main class="grey lighten-3">
    <CallApi ref="CallApi" />

    <v-app-bar
      dark
      color="#1C84AC"
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link :to="{name: 'u-02'}" style="color: white; text-decoration: none;">
          POSデータクレンジングサービス
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="mr-5 caption" outlined>
        <span style="font-size: 14px">
          {{login_user_maker_nm}}  {{ login_user_nm }}
        </span>

        <br />
        <span v-if="proxy_login_user_nm" style="font-size: 14px">
          代理ログイン：{{proxy_login_user_comp_nm}} {{ proxy_login_user_nm }}
        </span>
      </div>
      <v-btn
        @click="$refs.ManualDialog.open(manual_list)"
        outlined
        class="mr-2"
      >
        <v-icon size="30">mdi-book-open</v-icon>
        <h4>マニュアル</h4>
      </v-btn>
      <v-btn @click="logout_click" outlined>
        <v-icon size="30">mdi-logout</v-icon>
        <h4>ログアウト</h4>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      color="white"
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="70"
      clipped
      app
      mobile-breakpoint="100"
    >
      <div style="display: flex; flex-direction: column; height: 100%">
        <v-list>
          <v-list-item
            v-for="item in navigationList"
            :key="item.title"
            link
            @click="selectNavi(item)"
            :class="{ 'active-link': $route.name === item.routeName }"
            class="align-center"
            :disabled="disabled_view(item)"
          >
            <v-list-item-icon>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#1C84AC"
                    dark
                    small
                    fab
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <h4 style="fontsize: 18px">
                  {{ item.title }}
                </h4>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div style="flex-grow: 1"></div>

        <div class="px-2 mb-2">
          <v-card
            width="250px"
            height="250px"
            class="px-0 mt-2"
            color=""
            style="border: thin solid #1c84ac"
            v-if="!mini"
          >
            <v-card-text class="blue-grey--text" v-html="inquiry" />
          </v-card>
        </div>
      </div>
    </v-navigation-drawer>

    <router-view />

    <ManualDialog ref="ManualDialog" />
  </v-main>
</template>

<script>

import '@/assets/fontello/css/fontello.css';
import CallApi from '@/components/CallApi.vue';
import ManualDialog from './components/ManualDialog.vue';

export default {
  components: {
    CallApi,
    ManualDialog,
  },
  name: 'MainTemplate',
  data: () => ({
    navigationList: [
      { title: 'データアップロード', icon: 'mdi-upload', routeName: 'u-03' },
      { title: '履歴検索', icon: 'mdi-text-box-search', routeName: 'u-04' },
      { title: '商品マスタ', icon: 'icon-shampoo', routeName: 'u-10' },
      { title: '店舗マスタ', icon: 'mdi-store', routeName: 'u-11' },
      { title: 'ユーザー管理', icon: 'mdi-account-cog', routeName: 'u-12' },
    ],
    drawer: null,
    mini: null,

    login_user_nm: '',
    login_user_maker_nm: '',
    proxy_login_user_nm: '',
    proxy_login_user_comp_nm: '',
    role: {},
    use_maker_master_item: null,
    use_maker_master_store: null,
    inquiry: '',

    manual_list: [],
  }),

  // 初期画面表示用の値を取得する
  mounted() {
    this.$refs.CallApi.callApi(
      '/api/main_template/get_initial_info',
      {},
      (response) => {
        this.login_user_nm = response.data.login_user_nm;
        this.login_user_maker_nm = response.data.login_user_maker_nm;
        this.proxy_login_user_nm = response.data.proxy_login_user_nm;
        this.proxy_login_user_comp_nm = response.data.proxy_login_user_comp_nm;
        this.role = response.data.role;
        this.use_maker_master_item = response.data.use_maker_master_item;
        this.use_maker_master_store = response.data.use_maker_master_store;
        this.inquiry = response.data.inquiry;
        this.manual_list = response.data.manual_list;
      },
      () => {
        // エラーハンドリングは共通処理
      },
      {
        showError: false,
      },
    );
  },

  methods: {

    selectNavi(item) {
      if (this.$route.name === item.routeName) {
        return;
      }
      if (item.title === 'データアップロード') {
        this.$router.push({ name: 'u-03' });
      }
      if (item.title === '履歴検索') {
        this.$router.push({ name: 'u-04' });
      }
      if (item.title === '商品マスタ') {
        this.$router.push({ name: 'u-10' });
      }
      if (item.title === '店舗マスタ') {
        this.$router.push({ name: 'u-11' });
      }
      if (item.title === 'ユーザー管理') {
        this.$router.push({ name: 'u-12' });
      }
      if (item.title === 'ログアウト') {
        this.$router.push({ name: 'u-01' });
      }
    },
    logout_click() {
      this.$refs.CallApi.callApi(
        '/api/u-01/logout',
        {},
        () => {
          localStorage.accessToken = null;
          localStorage.refreshToken = null;
          this.$router.push({ name: 'u-01' });
        },
        () => {
          // エラーハンドリングは共通処理
        },
      );
    },

    disabled_view(item) {
      if (item.title === 'データアップロード') {
        return !this.role.u03;
      }
      if (item.title === '履歴検索') {
        return !this.role.u04;
      }
      if (item.title === '商品マスタ') {
        return !this.role.u10 || !this.use_maker_master_item;
      }
      if (item.title === '店舗マスタ') {
        return !this.role.u11 || !this.use_maker_master_store;
      }
      if (item.title === 'ユーザー管理') {
        return !this.role.u12;
      }
      return false;
    },

  },
};
</script>

<style>
.active-link {
  background-color: rgba(28, 131, 172, 0.2);
}
</style>
