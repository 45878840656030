<!--
    画面ID：CreateDownloadFileDialog
    画面名：ダウンロードファイル作成ダイアログ
-->
<template>
  <div>
    <!-- ダウンロードボタンまたは一括ダウンロードボタンを押下した際のダイアログ -->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark size="40">
          <div class="text-h6 pa-5">{{title}}</div>
        </v-toolbar>
        <v-card-text>
          <div>
            <v-row justify="center">
              <div class="text-h6 px-12 pt-12 pb-5">
                ダウンロードファイルのレイアウトを選択
              </div>
            </v-row>
            <v-row justify="center" class="px-12 mx-8 mb-1">
              <v-autocomplete
                :items="output_layout_list"
                v-model="selected_ouput_layout_id"
                class=""
                label=""
                item-text="layout_nm"
                item-value="id"
                outlined
                background-color="white"
                color="#1C84AC"
              ></v-autocomplete>
            </v-row>
          </div>
          <v-row justify="center" class="px-12 mt-n5">
            <v-checkbox class="ml-5" color="#1C84AC" v-model="exclude_error"/>
            <div class="text-h6 mt-4">不整合データを除外</div>
          </v-row>
          <v-row justify="center" class="px-12">
            <v-checkbox class="mt-n3" color="#1C84AC" v-model="exclude_unmatch_item"/>
            <div class="text-h6 mt-n3">未照合商品を除外</div>
          </v-row>
          <v-row justify="center" class="px-12">
            <v-checkbox class="mt-n3" color="#1C84AC" v-model="exclude_unmatch_store"/>
            <div class="text-h6 mt-n3">未照合店舗を除外</div>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center pb-8">
          <v-btn
            dark
            color="blue-grey"
            @click="no_Click"
            width="30%"
            height="50"
          >
            <div class="text-h6">閉じる</div>
          </v-btn>
          <v-btn
            dark
            color="#1C84AC"
            @click="yes_Click"
            width="30%"
            height="50"
          >
            <div class="text-h6">決定</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    output_layout_list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,

    title: '',

    selected_ouput_layout_id: null,
    exclude_error: false,
    exclude_unmatch_item: false,
    exclude_unmatch_store: false,

    yesCallback: null,
    noCallback: null,

  }),
  methods: {
    // ダイアログを開く
    open(title, yesCallback, noCallback) {
      this.reset_param();

      this.dialog = true;
      this.title = title;
      this.yesCallback = yesCallback;
      this.noCallback = noCallback;
    },
    yes_Click() {
      if (this.yesCallback) {
        this.yesCallback();
      }
      this.dialog = false;
    },
    no_Click() {
      if (this.noCallback) {
        this.noCallback();
      }
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
    reset_param() {
      this.selected_ouput_layout_id = null;
      this.exclude_error = false;
      this.exclude_unmatch_item = false;
      this.exclude_unmatch_store = false;
    },
    send_param_parent() {
      // 親コンポーネントに入力条件を渡す
      const inputList = [];
      inputList.push(this.selected_ouput_layout_id);
      inputList.push(this.exclude_error);
      inputList.push(this.exclude_unmatch_item);
      inputList.push(this.exclude_unmatch_store);
      return inputList;
    },
  },
};
</script>
