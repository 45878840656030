<!--
    画面ID：LoadingDialog
    画面名：共通処理：ロード時のアニメーション表示
-->
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-text>
          <v-row justify="center" class="pt-13 mb-2">
            <v-icon x-large color="">mdi-gamepad-circle-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <div class="text-h5 px-12 py-6">しばらくお待ち下さい...</div>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-progress-linear
            indeterminate
            striped
            height="10"
            color="grey"
          ></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'loadingDialog',
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>
