<template>
  <span
    ref="content"
    :style="styleObject"
  >
    <v-tooltip v-if="isOverflowing" bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >
          {{ content }}
        </span>
      </template>
      <span>
        {{ content }}
      </span>
    </v-tooltip>
    <template v-else>
      {{ content }}
    </template>
  </span>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '200px',
    },
    content: {
      type: String,
      default: '',
    },
    specialStyleObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOverflowing: false,
    };
  },
  mounted() {
    this.checkOverflow();

    // リサイズイベントの監視
    window.addEventListener('resize', this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOverflow);

    // MutationObserverの監視を終了
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  },
  methods: {
    checkOverflow() {
      this.$nextTick(() => {
        const element = this.$refs.content;
        this.isOverflowing = element.offsetWidth < element.scrollWidth;
      });
    },
  },
  watch: {
    content: 'checkOverflow', // contentプロパティが変更されたときにcheckOverflowメソッドを呼ぶ
  },
  computed: {
    styleObject() {
      return {
        ...this.specialStyleObject,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        maxWidth: this.maxWidth,
        verticalAlign: 'middle',
      };
    },
  },
};
</script>
