<!--
    画面名：データアップロード画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <!-- ダイアログ定義 -->
    <uploadCompleteDialog ref="uploadCompleteDialog" />
    <uploadingDialog ref="uploadingDialog" />

    <!-- 底部 -->
    <footerParts v-on:get_presigned_url="get_presigned_url" />

    <v-main class="grey lighten-3 pa-0 align-start">
      <v-container fluid class="">
        <v-card class="mt-5 mx-5 pb-8 grey lighten-5">
          <v-card-title class="mb-8"
            ><h3>データアップロード画面</h3></v-card-title
          >
          <v-row justify="start" class="mt-0 mx-13">
            <v-col cols="3" class="mt-3 mr-n10">
              <h2>レイアウト名を選択</h2>
            </v-col>
            <v-col cols="5">
              <v-select
                v-model="selected_input_layout_id"
                :items="input_layout_list"
                item-text="layout_nm"
                item-value="id"
                @input="select_layout"
                class=""
                label=""
                outlined
                background-color="white"
                color="#1C84AC"
              ></v-select>
            </v-col>
          </v-row>

          <v-row justify="start" class="mx-13">
            <v-col cols="3" class="mt-3 mr-n10">
              <h2>ファイルを選択</h2>
            </v-col>
            <v-col cols="5">
              <v-file-input
                truncate-length="50"
                v-model="selected_file"
                label="ファイル"
                class=""
                outlined
                background-color="white"
                color="#1C84AC"
              />
            </v-col>
          </v-row>

          <v-row justify="start" class="mx-13" v-if="!exist_purchase_date">
            <v-col cols="3" class="mt-3 mr-n10">
              <h2>期間を選択</h2>
            </v-col>
            <v-col cols="2" class="">
              <customDatePicker
                label=""
                :date="purchase_date_from"
                @update:date="update_purchase_date_from"
              />
            </v-col>

            <v-col cols="1" class="mt-3">
              <span style="font-size: 22px" class="ml-8">～</span>
            </v-col>

            <v-col cols="2">
              <customDatePicker
                label=""
                :date="purchase_date_to"
                @update:date="update_purchase_date_to"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import uploadCompleteDialog from '@/components/UploadCompleteDialog.vue';
import uploadingDialog from '@/components/UploadingDialog.vue';
import footerParts from './FooterParts.vue';
import customDatePicker from '../../components/CustomDatePicker.vue';
import CallApi from '../../components/CallApi.vue';

export default {
  components: {
    footerParts,
    uploadingDialog,
    CallApi,
    uploadCompleteDialog,
    customDatePicker,
  },

  data: () => ({
    input_layout_list: [],
    selected_input_layout_id: null,
    selected_file: null,
    purchase_date_from: null,
    purchase_date_to: null,
    exist_purchase_date: true,

    upload_number: '',
    upload_file_uri: '',
  }),

  // 初期画面出力
  mounted() {
    // API「get_initial_info」を呼び、一覧を得る。
    this.$refs.CallApi.callApi(
      'api/u-03/get_initial_info',
      {},
      (response) => {
        // APIが正常終了した場合
        this.input_layout_list = response.data.input_layout_list;
      },
      () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
      },
    );
  },

  methods: {
    get_presigned_url() {
      this.$refs.CallApi.callApi(
        'api/u-03/get_presigned_url',
        {
          input_layout_id: this.selected_input_layout_id,
          upload_file_nm: this.selected_file ? this.selected_file.name : '',
          upload_file_size: this.selected_file ? this.selected_file.size : 0,
          purchase_date_from: this.purchase_date_from,
          purchase_date_to: this.purchase_date_to,
          exist_purchase_date: this.exist_purchase_date,
        },
        (response) => {
        // APIが正常終了した場合
          this.presigned_url = response.data.presigned_url;
          this.upload_number = response.data.upload_number;
          this.upload_file_uri = response.data.upload_file_uri;
          this.upload();
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
    upload() {
      this.$refs.uploadingDialog.open(
        this.presigned_url,
        this.selected_file,
        () => {
        // 署名付きURLに対してのアップロード処理が正常終了した場合
          this.register_task(this.selected_file.name);
          this.$refs.uploadingDialog.close();
          this.selected_file = null;
        },
        () => {
          this.selected_file = null;
        },
      );
    },
    register_task() {
      this.$refs.CallApi.callApi(
        'api/u-03/register_task',
        {
          upload_file_uri: this.upload_file_uri,
          upload_number: this.upload_number,
          upload_file_size: this.selected_file ? this.selected_file.size : 0,
          input_layout_id: this.selected_input_layout_id,
          purchase_date_from: this.purchase_date_from,
          purchase_date_to: this.purchase_date_to,
        },
        () => {
          // APIが正常終了した場合
          this.$refs.uploadCompleteDialog.open();
        },
        () => {
          // エラーが発生した場合
        },
      );
    },

    // レイアウト選択時
    select_layout() {
      this.purchase_date_from = null;
      this.purchase_date_to = null;

      this.$refs.CallApi.callApi(
        'api/u-03/check_exist_purchese_date_in_layout',
        {
          input_layout_id: this.selected_input_layout_id,
        },
        (response) => {
          // APIが正常終了した場合
          this.exist_purchase_date = response.data.exist_purchase_date;
        },
        () => {
          // APIでエラーが発生した場合
        },
      );
    },
    update_purchase_date_from(date) {
      this.purchase_date_from = date;
    },
    update_purchase_date_to(date) {
      this.purchase_date_to = date;
    },
  },
};
</script>
