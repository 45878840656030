<!--
    画面ID：uploadingDialog
    画面名：u-10 アップロード中ダイアログ
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card color="grey lighten-3">
        <v-card-text>
          <v-row justify="center" class="pt-13 mb-2">
            <v-icon x-large :color="icon_color">{{ iconName }}</v-icon>
          </v-row>
          <v-row justify="center">
            <div
              style="white-space: pre-line"
              class="text-h5 px-10 pb-10"
              v-if="true"
            >
              {{ message }}
            </div>
          </v-row>
          <v-progress-linear
            striped
            height="25"
            v-model="progress"
            :color="progress_color"
            style="pointer-events: none"
          >
            <strong class="white--text">{{ Math.ceil(progress) }}%</strong>
          </v-progress-linear>
        </v-card-text>
        <v-card-actions class="pb-8 pt-5 white">
          <v-row justify="center">
            <v-btn
              color="grey lighten-3"
              large
              class="mt-3"
              width="25%"
              height="50"
              @click="close_click()"
            >
              <div class="text-h6">{{ message2 }}</div>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import CallApi from '@/components/CallApi.vue';

export default {
  components: {
    CallApi,

  },
  data: () => ({
    dialog: false,
    message: 'しばらくお待ち下さい...',
    message2: 'キャンセル',
    iconName: 'mdi-progress-upload',
    icon_color: '',
    progress: 0,
    progress_color: 'grey darken-2',
    source: '',

  }),

  methods: {
    // 署名付きURLに対してファイルをアップロードする
    open(signedUrl, file, callbackNormal, callbackError) {
      this.reset_element();
      this.dialog = true;
      // CancelToken を作成
      this.source = axios.CancelToken.source();
      const config = {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Access-Control-Allow-Origin': '*',
        },
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        cancelToken: this.source.token, // CancelToken をリクエストの設定に追加
      };
      axios.put(signedUrl, file, config)
        .then(() => {
          callbackNormal();
        })
        .catch((error) => {
          // キャンセルボタン押下によるエラーかどうかを判断
          if (axios.isCancel(error)) {
            console.log('アップロードキャンセル', error);
          } else {
            console.error('Error during file upload:', error);
            this.message = 'ファイルアップロードに失敗しました。\n再度アップロード処理を行ってください。';
            this.iconName = 'mdi-alert';
            this.icon_color = 'deep-orange';
            this.progress_color = 'red';
            this.message2 = '閉じる';
            callbackError();
          }
        });
    },
    close() {
      this.dialog = false;
    },
    close_click() {
      if (this.message2 === '閉じる') {
        this.dialog = false;
      } else {
        // キャンセル操作
        this.source.cancel('Operation canceled by the user.');
        this.dialog = false;
      }
    },
    reset_element() {
      this.message = 'しばらくお待ち下さい...';
      this.iconName = 'mdi-progress-upload';
      this.icon_color = '';
      this.progress_color = 'grey darken-2';
      this.message2 = 'キャンセル';
      this.progress = 0;
    },
  },
};
</script>
