var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('confirmDialog',{ref:"confirmDialog"}),_c('CallApi',{ref:"CallApi"}),_c('v-row',{staticClass:"ml-n6 mt-8",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 history-table-column-fixed",attrs:{"headers":_vm.headers,"items":_vm.history_list,"item-key":"id","hide-default-footer":"","height":_vm.$vuetify.breakpoint.height - 390,"item-class":_vm.row_classes,"items-per-page":-1},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:`item.is_check`,fn:function({ item, index }){return [(
              item.status_cd != '取込中' &&
              item.status_cd != 'クレンジング中' &&
              item.status_cd != 'ファイル作成中' &&
              item.valid_for_status != '期限切れ'
            )?_c('td',[_c('v-simple-checkbox',{key:index,attrs:{"value":item},on:{"click":function($event){return _vm.onCheckboxClick(item)}},model:{value:(item.is_check),callback:function ($$v) {_vm.$set(item, "is_check", $$v)},expression:"item.is_check"}})],1):_vm._e()]}},{key:`item.valid_for_status`,fn:function({ item }){return [(item.valid_for_status == '期限切れ')?_c('v-chip',{attrs:{"label":"","outlined":"","color":"black"}},[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-file-cancel ")]),_c('h3',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.valid_for_status)+" ")])],1):(item.valid_for_status != '')?_c('v-chip',{style:({ border: '1px solid black !important' }),attrs:{"label":"","color":"amber lighten-3"}},[_c('v-icon',{attrs:{"color":""}},[_vm._v(" mdi-file-alert-outline ")]),_c('h3',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.valid_for_status)+" ")])],1):_vm._e()]}},{key:`item.download`,fn:function({ item }){return [(
              ['ファイル作成済', 'ダウンロード済'].includes(item.status_cd) &&
              item.valid_for_status != '期限切れ'
            )?_c('v-btn',{staticStyle:{"border":"thin solid #1c84ac"},attrs:{"small":"","fab":"","color":"","elevation":"2"},on:{"click":function($event){return _vm.buttonClicked($event, item)}}},[_c('v-icon',{attrs:{"color":"#1C84AC"}},[_vm._v(" mdi-download ")])],1):_vm._e()]}},{key:`item.status_cd`,fn:function({ item }){return [_c('CustomTooltip',{attrs:{"maxWidth":'220px',"content":item.status_cd,"specialStyleObject":item.status_cd === '取込異常終了' ||
              item.status_cd === 'クレンジング異常終了' ||
              item.status_cd === 'ファイル作成異常終了'
                ? { color: 'red' }
                : {}}})]}},{key:`item.upload_file_uri`,fn:function({ item }){return [_c('CustomTooltip',{attrs:{"maxWidth":'300px',"content":item.upload_file_uri}})]}},{key:`item.input_layout_.layout_nm`,fn:function({ item }){return [_c('CustomTooltip',{attrs:{"maxWidth":'400px',"content":item.input_layout_.layout_nm}})]}},{key:`item.upload_user.user_nm`,fn:function({ item }){return [_c('CustomTooltip',{attrs:{"maxWidth":'240px',"content":item.upload_user.user_nm}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-5"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }