<!--
    画面ID：TableParts
    画面名：u-04 一覧部分
-->
<template>
  <div>
    <confirmDialog ref="confirmDialog" />
    <CallApi ref="CallApi"></CallApi>
    <v-row class="ml-n6 mt-8" align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="history_list"
          item-key="id"
          class="elevation-1 history-table-column-fixed"
          hide-default-footer
          :height="$vuetify.breakpoint.height - 390"
          :item-class="row_classes"
          @click:row="clickRow"
          :items-per-page="-1"
        >
          <!-- チェックボックス -->
          <template v-slot:[`item.is_check`]="{ item, index }">
            <td
              v-if="
                item.status_cd != '取込中' &&
                item.status_cd != 'クレンジング中' &&
                item.status_cd != 'ファイル作成中' &&
                item.valid_for_status != '期限切れ'
              "
            >
              <v-simple-checkbox
                v-model="item.is_check"
                :value="item"
                :key="index"
                @click="onCheckboxClick(item)"
              ></v-simple-checkbox>
            </td>
          </template>

          <!-- 期限表示ラベル -->
          <template v-slot:[`item.valid_for_status`]="{ item }">
            <v-chip
              label
              outlined
              color="black"
              class=""
              v-if="item.valid_for_status == '期限切れ'"
            >
              <v-icon color=""> mdi-file-cancel </v-icon>
              <h3 class="font-weight-medium">
                {{ item.valid_for_status }}
              </h3>
            </v-chip>

            <v-chip
              label
              color="amber lighten-3"
              class=""
              v-else-if="item.valid_for_status != ''"
              :style="{ border: '1px solid black !important' }"
            >
              <v-icon color=""> mdi-file-alert-outline </v-icon>
              <h3 class="font-weight-medium">
                {{ item.valid_for_status }}
              </h3>
            </v-chip>
          </template>

          <!-- ダウンロードボタン -->
          <template v-slot:[`item.download`]="{ item }">
            <v-btn
              small
              fab
              color=""
              class=""
              elevation="2"
              style="border: thin solid #1c84ac"
              @click="buttonClicked($event, item)"
              v-if="
                ['ファイル作成済', 'ダウンロード済'].includes(item.status_cd) &&
                item.valid_for_status != '期限切れ'
              "
            >
              <v-icon color="#1C84AC"> mdi-download </v-icon>
            </v-btn>
          </template>

          <!-- 状態 -->
          <template v-slot:[`item.status_cd`]="{ item }">
            <CustomTooltip
              :maxWidth="'220px'"
              :content="item.status_cd"
              :specialStyleObject="
                item.status_cd === '取込異常終了' ||
                item.status_cd === 'クレンジング異常終了' ||
                item.status_cd === 'ファイル作成異常終了'
                  ? { color: 'red' }
                  : {}
              "
            />
          </template>

          <!-- ファイル名 -->
          <template v-slot:[`item.upload_file_uri`]="{ item }">
            <CustomTooltip
              :maxWidth="'300px'"
              :content="item.upload_file_uri"
            />
          </template>

          <!-- レイアウト名 -->
          <template v-slot:[`item.input_layout_.layout_nm`]="{ item }">
            <CustomTooltip
              :maxWidth="'400px'"
              :content="item.input_layout_.layout_nm"
            />
          </template>

          <!-- アップロードユーザー -->
          <template v-slot:[`item.upload_user.user_nm`]="{ item }">
            <CustomTooltip
              :maxWidth="'240px'"
              :content="item.upload_user.user_nm"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row class="mt-5"> </v-row>
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';
import confirmDialog from '@/components/ConfirmDialog.vue';
import CustomTooltip from '@/components/CustomTooltip.vue';

export default {
  components: {
    confirmDialog,
    CallApi,
    CustomTooltip,
  },
  props: {
    is_admin: {
      type: Boolean,
      required: true,
    },
    history_list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedItems: [],
    headers: [
      {
        text: '', value: 'is_check', width: 64,
      },
      {
        text: '状態', value: 'status_cd', width: 220, class: 'white--text',
      },
      {
        text: '', value: 'valid_for_status', width: 150, class: 'white--text',
      },
      {
        text: 'ﾀﾞｳﾝﾛｰﾄﾞ', value: 'download', width: 130, class: 'white--text',
      },
      {
        text: 'ファイル名', value: 'upload_file_uri', width: 300, class: 'white--text',
      },
      {
        text: 'レイアウト名', value: 'input_layout_.layout_nm', width: 400, class: 'white--text',
      },
      {
        text: '取込日時', value: 'upload_at', width: 200, class: 'white--text',
      },
      {
        text: '取込件数', value: 'input_count', width: 200, class: 'white--text',
      },
      {
        text: 'クレンジング件数', value: 'cleansing_count', width: 230, class: 'white--text',
      },
      {
        text: '出力件数', value: 'output_count', width: 180, class: 'white--text',
      },
      {
        text: 'アップロードユーザー', value: 'upload_user.user_nm', width: 240, class: 'white--text',
      },
    ],
  }),

  // 初期画面出力
  watch: {
    is_admin(newVal) {
      if (!newVal) {
      // 管理者以外の場合「アップロードユーザー」のカラムを除外して返す
        this.headers = this.headers.filter((header) => header.value !== 'upload_user');
      }
    },
  },

  methods: {
    clickRow(item) {
      this.$emit('open_datail', item);
    },

    row_classes(item) {
      if (item.valid_for_status === '期限切れ') {
        return 'over_limit';
      }
      if (item.valid_for_status === '取込 異常終了' || item.valid_for_status === 'クレンジング 異常終了' || item.valid_for_status === 'ファイル作成 異常終了') {
        return 'error_status';
      }
      if (item.error_count > 0 || item.duplicate_count > 0) {
        return 'error_';
      }

      return '';
    },
    methods: {
      isErrorStatus(status) {
        const errorStatuses = ['取込異常終了', 'クレンジング異常終了', 'ファイル作成異常終了'];
        return errorStatuses.includes(status);
      },
    },

    showConfirmDialog() {
      this.$refs.confirmDialog.open('テスト');
    },
    showErrorDialog() {
      this.$refs.errorDialog.open('テスト');
    },
    showLoadingDialog() {
      this.$refs.loadingDialog.open();
    },

    onCheckboxClick(item) {
      if (item.is_check) {
        // 選択されたアイテムをリストに追加
        this.selectedItems.push(item);
      } else {
        // 選択が解除されたアイテムをリストから削除
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      // 新しい値を親コンポーネントに送信
      this.$emit('selected_changed', this.selectedItems);
    },

    buttonClicked(event, item) {
      // ダウンロードボタンクリック時は詳細画面を開かないように制御
      event.stopPropagation();

      this.$refs.CallApi.callApi(
        'api/u-04/get_presigned_url',
        {
          id_list: [item.id],
        },
        (response) => {
        // APIが正常終了した場合
          this.presigned_url_list = response.data.presigned_url_list;

          this.presigned_url_list.forEach((url) => {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            const evt = document.createEvent('MouseEvents');
            evt.initEvent('click', false, true);
            a.dispatchEvent(evt);
          });

          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // 選択初期化（親から呼ぶ）
    clear_select() {
      this.selectedItems = [];
    },
  },

};
</script>
