<template>
  <v-dialog
    v-model="dialog"
    v-bind:max-width="width"
    v-bind:max-height="height"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ title }}
          <v-icon
            class="ml-1 mb-1"
            v-if="
              title == '新規登録' ||
              title == '新規登録（管理者）' ||
              title == '新規登録（メーカー）' ||
              title == '新規登録（ユーザー）'
            "
            >mdi-plus-circle-outline</v-icon
          >
          <v-icon
            class="ml-1 mb-1"
            v-if="
              title == '編集' ||
              title == '編集（管理者）' ||
              title == '編集（メーカー）' ||
              title == '編集（ユーザー）'
            "
            >mdi-pen</v-icon
          >
        </span>
      </v-card-title>
      <v-divider />

      <v-card-text v-bind:style="{ height: height + 'px' }">
        <!-- ここに各画面でコンポーネントを埋め込む -->
        <slot />
      </v-card-text>

      <v-divider class="" style="z-index: 1000"></v-divider>
      <v-card-actions style="z-index: 1000">
        <v-spacer></v-spacer>
        <v-btn
          height="50"
          :color="color"
          class=""
          @click="no_Click"
          elevation="1"
          dark
          outlined
        >
          <h3 class="font-weight-bold">キャンセル</h3>
        </v-btn>
        <v-btn
          height="50"
          :color="color"
          class=""
          @click="yes_Click"
          elevation="1"
          outlined
          dark
        >
          <h3
            class="font-weight-bold"
            v-if="
              title == '新規登録' ||
              title == '新規登録（管理者）' ||
              title == '新規登録（ユーザー）' ||
              title == '新規登録（メーカー）'
            "
          >
            登録
          </h3>
          <h3
            class="font-weight-bold"
            v-if="
              title == '編集' ||
              title == '編集（管理者）' ||
              title == '編集（ユーザー）' ||
              title == '編集（メーカー）'
            "
          >
            保存
          </h3>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/*
 * @vuese
 * 画面ID：RegisterDialog
 * 画面名：共通処理：データ登録または編集ダイアログのタイトルとボタン部分
 */

export default {
  data: () => ({
    width: 0,
    height: 0,
    dialog: false,
    yesCallback: null,
    noCallback: null,
    title: '',
    color: '',
    buttonStyle: {
      border: '',
    },
  }),
  methods: {
    open(width, height, title, isUser, yesCallback, noCallback) {
      this.width = width;
      this.height = height;
      this.title = title;
      this.yesCallback = yesCallback;
      this.noCallback = noCallback;
      this.dialog = true;
      if (isUser) {
        this.color = '#1C84AC';
        this.buttonStyle.border = 'thin solid #1C84AC';
      } else {
        this.color = '#F48FB1';
        this.buttonStyle.border = 'thin solid #F48FB1';
      }
    },
    yes_Click() {
      if (this.yesCallback) {
        this.yesCallback();
      }
    },
    no_Click() {
      if (this.noCallback) {
        this.noCallback();
      }
      this.dialog = false;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
