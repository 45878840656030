<!--
    画面ID：
    画面名：共通コンポーネント：カスタムdatepicker
-->
<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatDate(date)"
        :label="label"
        prepend-inner-icon="mdi-calendar"
        readonly
        clearable
        outlined
        background-color="white"
        v-bind="attrs"
        v-on="on"
        style="font-size: 15px"
        @click:clear="$emit('update:date', '')"
        color="#1C84AC"
      />
    </template>
    <v-date-picker
      :class="{ 'weekend-style': !isLimit }"
      :value="date"
      :allowed-dates="allowedDates"
      @input="
        visible = false;
        $emit('update:date', $event);
      "
      no-title
      scrollable
      locale="jp-ja"
      :day-format="(date) => new Date(date).getDate()"
    />
  </v-menu>
</template>

<script>

export default {
  data: () => ({
    visible: false,
  }),
  props: {
    label: {
      type: String,
    },
    date: {
      type: String,
      default: null,
      required: true,
    },
    isLimit: {
      type: Boolean,
      default: false,
    },
    history_valid_days: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    allowedDates(value) {
      if (this.isLimit) {
        const today = new Date();
        const limitDate = new Date();
        limitDate.setDate(today.getDate() - this.history_valid_days + 1);
        return new Date(value) <= today && new Date(value) >= limitDate;
      }
      return true;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${year}/${month}/${day}`;
    },

  },
};
</script>
