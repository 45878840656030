<!--
    画面名：未照合商品確認画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <v-row justify="end" class="mt-7">
      <v-col cols="4">
        <v-select
          label=""
          outlined
          dense
          background-color="white"
          v-model="selected_filter"
          :items="unmatch_item_filter_list"
          item-text="text"
          item-value="idx"
          @input="select_filter"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="ml-n6 mt-n10" align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="unmatch_item_list"
          class="elevation-1 unmatch-item-table-column-fixed"
          hide-default-footer
          :height="$vuetify.breakpoint.height - 450"
          :items-per-page="1000"
        >
          <!-- セルに色を付ける -->
          <template v-slot:item="{ item }">
            <tr>
              <td
                v-for="header in headers"
                :key="header.value"
                :class="
                  getCellStyle(
                    header.value,
                    item['TD商品マスタ照合結果'] === '未照合',
                    item['メーカ商品マスタ照合結果'] === '未照合'
                  )
                "
              >
                <!-- 要素が長い場合に省略するように設定 -->
                <CustomTooltip
                  :maxWidth="header.value === '商品名' ? '300px' : '220px'"
                  :content="item[header.value] || ''"
                />
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';
import CustomTooltip from '@/components/CustomTooltip.vue';

export default {
  components: {
    CallApi,
    CustomTooltip,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    unmatch_item_filter_list: {
      type: Array,
      required: true,
    },
    unmatch_item_list: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected_filter: null,
  }),

  watch: {
    // プロパティが更新されたときに selectedItem を初期化する
    unmatch_item_filter_list: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          // // リストの0番目の項目を初期選択として設定
          const selectedItem = newValue[0].idx;
          this.selected_filter = selectedItem;
        }
      },
    },
  },

  methods: {

    getCellStyle(header, tdUnmatch, makerUnmatch) {
      const isTdHeader = header.includes('TD');
      const isMakerHeader = header.includes('M商品');

      if (tdUnmatch && isTdHeader) {
        return 'pink lighten-4';
      }
      if (makerUnmatch && isMakerHeader) {
        return 'pink lighten-4';
      }

      return '';
    },

    // 絞り込み選択肢選択時
    select_filter() {
      this.$emit('select_filter', this.selected_filter);
    },

    // エクスポートボタン押下時
    get_presigned_url() {
      this.$refs.CallApi.callApi(
        'api/u-08/get_presigned_url',
        {
          history_id: this.$route.query.history_id,
          unmatched_item_filter_index: this.selected_filter,
        },
        (response) => {
        // APIが正常終了した場合
          window.location.href = response.data.presigned_url;
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
  },
};
</script>
