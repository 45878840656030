import Vue from 'vue';
import VueRouter from 'vue-router';

import MainTemplate from '../MainTemplate.vue';
import TopTemplate from '../TopTemplate.vue';
import U01 from '../views/u-01/u-01.vue';
import U02 from '../views/u-02/u-02.vue';
import U03 from '../views/u-03/u-03.vue';
import U04 from '../views/u-04/u-04.vue';
import U05 from '../views/u-05/u-05.vue';
import U06 from '../views/u-06/u-06.vue';
import U08 from '../views/u-08/u-08.vue';
import U09 from '../views/u-09/u-09.vue';
import U10 from '../views/u-10/u-10.vue';
import U11 from '../views/u-11/u-11.vue';
import U12 from '../views/u-12/u-12.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'u-01',
    component: U01,
  },
  // ログイン画面
  {
    path: `/${btoa('u-01')}`,
    name: 'u-01__',
    component: U01,
  },
  // トップ画面
  {
    path: '/layout/02',
    name: 'TopTemplate',
    component: TopTemplate,
    children: [
      {
        path: `/${btoa('u-02')}`,
        name: 'u-02',
        component: U02,
      },
    ],
  },
  // それ以降の画面
  {
    path: '/layout/03',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-03')}`,
        name: 'u-03',
        component: U03,
      },
    ],
  },
  {
    path: '/layout/04',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-04')}`,
        name: 'u-04',
        component: U04,
      },
    ],
  },
  {
    path: '/layout/05',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-05')}`,
        name: 'u-05',
        component: U05,
      },
    ],
  },
  {
    path: '/layout/06',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-06')}`,
        name: 'u-06',
        component: U06,
      },
    ],
  },
  {
    path: '/layout/08',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-08')}`,
        name: 'u-08',
        component: U08,
      },
    ],
  },
  {
    path: '/layout/09',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-09')}`,
        name: 'u-09',
        component: U09,
      },
    ],
  },
  {
    path: '/layout/10',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-10')}`,
        name: 'u-10',
        component: U10,
      },
    ],
  },
  {
    path: '/layout/11',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-11')}`,
        name: 'u-11',
        component: U11,
      },
    ],
  },
  {
    path: '/layout/12',
    name: 'MainTemplate',
    component: MainTemplate,
    children: [
      {
        path: `/${btoa('u-12')}`,
        name: 'u-12',
        component: U12,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
