<!--
    画面名：ユーザー管理画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <registerDialog ref="registerDialog">
      <v-container>
        <v-row>
          <v-col cols="12" class="mt-3">
            <v-text-field
              v-model="items.login_username"
              label="メールアドレス"
              outlined
              color="#1C84AC"
              prepend-inner-icon="mdi-email"
              maxlength="320"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n4">
            <v-text-field
              v-model="items.user_nm"
              label="ユーザー名"
              outlined
              color="#1C84AC"
              prepend-inner-icon="mdi-account"
              maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mt-n4">
            <v-select
              v-model="items.role"
              label="権限"
              :items="role_list"
              item-text="role_nm"
              item-value="id"
              return-object
              color="#1C84AC"
              outlined
              background-color="white"
              prepend-inner-icon="mdi-security"
              @input="updateValue"
            />
          </v-col>

          <v-col cols="12" class="mt-n4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="使用可能レイアウト検索"
              single-line
              color="#1C84AC"
            ></v-text-field>

            <v-data-table
              v-model="items.input_layout_list"
              :headers="shownHeaders"
              :items="available_layout_list"
              hide-default-footer
              item-key="id"
              show-select
              class="mb-4"
              fixed-header
              height="300px"
              @input="retail_Change"
              :search="search"
              :items-per-page="-1"
            ></v-data-table>
            <v-chip
              v-for="chip in items.input_layout_list"
              :key="chip"
              class="mb-1 mr-1"
            >
              {{ chip.layout_nm }}
            </v-chip>
          </v-col>
        </v-row>
      </v-container>
    </registerDialog>
  </div>
</template>

<script>

import registerDialog from '@/components/RegisterDialog.vue';
import CallApi from '@/components/CallApi.vue';

export default {
  components: {
    registerDialog,
    CallApi,
  },
  props: {
    role_list: {
      type: Array,
      required: true,
    },
    available_layout_list: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    shownHeaders: [
      {
        text: '使用可能レイアウト',
        align: 'start',
        value: 'layout_nm',
        class: 'white--text',
      },
    ],
    search: '',
    items: {
      id: 0,
      login_username: '',
      user_nm: '',
      role: {},
      input_layout_list: [],
    },
    defaultItems: {
      id: 0,
      login_username: '',
      user_nm: '',
      role: {},
      input_layout_list: [],
    },
  }),
  methods: {
    // 新規登録ボタン押下時
    register_dialog_open() {
      this.reset_element();
      this.$refs.registerDialog.open(
        700,
        750,
        '新規登録',
        true,
        () => {
        // 「登録」の場合
        // 小売情報を登録する。
          this.register_user();
        },
        () => {
        // 「キャンセル」の場合は何もしない
        },
      );
    },
    register_user() {
      // id_listに選択行のidを格納する
      const selectedInputLayoutIdList = this.items.input_layout_list.map((item) => item.id);
      // API呼び出し
      this.$refs.CallApi.callApi(
        'api/u-12/register_user',
        {
          login_username: this.items.login_username,
          user_nm: this.items.user_nm,
          role_id: this.items.role.id,
          input_layout_id_list: selectedInputLayoutIdList,
        },
        () => {
          // APIでエラーが発生しなかった場合
          this.$refs.registerDialog.close();
          this.$emit('search');
        },
        () => {
          // APIでエラーが発生した場合
        },
      );
    },
    // 編集ボタン押下時
    edit_dialog_open(items) {
      this.items = items;

      this.$refs.registerDialog.open(
        700,
        750,
        '編集',
        true,
        () => {
        // 「保存」の場合
        // 小売情報を更新する。
          this.update_user();
        },
        () => {
        // 「キャンセル」の場合は何もしない
        },
      );
    },
    update_user() {
      // id_listに選択行のidを格納する
      const selectedInputLayoutIdList = this.items.input_layout_list.map((item) => item.id);
      this.$refs.CallApi.callApi(
        'api/u-12/update_user',
        {
          id: this.items.id,
          login_username: this.items.login_username,
          user_nm: this.items.user_nm,
          role_id: this.items.role.id,
          input_layout_id_list: selectedInputLayoutIdList,

        },
        () => {
          // APIでエラーが発生しなかった場合
          this.$refs.registerDialog.close();
          this.$emit('search');
        },
        () => {
          // APIでエラーが発生した場合
        },
      );
    },
    reset_element() {
      this.items = { ...this.defaultItems };
      this.input_layout_list = [];
    },
  },
};
</script>
