var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":_vm.width,"max-height":_vm.height,"persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" "),(
            _vm.title == '新規登録' ||
            _vm.title == '新規登録（管理者）' ||
            _vm.title == '新規登録（メーカー）' ||
            _vm.title == '新規登録（ユーザー）'
          )?_c('v-icon',{staticClass:"ml-1 mb-1"},[_vm._v("mdi-plus-circle-outline")]):_vm._e(),(
            _vm.title == '編集' ||
            _vm.title == '編集（管理者）' ||
            _vm.title == '編集（メーカー）' ||
            _vm.title == '編集（ユーザー）'
          )?_c('v-icon',{staticClass:"ml-1 mb-1"},[_vm._v("mdi-pen")]):_vm._e()],1)]),_c('v-divider'),_c('v-card-text',{style:({ height: _vm.height + 'px' })},[_vm._t("default")],2),_c('v-divider',{staticStyle:{"z-index":"1000"}}),_c('v-card-actions',{staticStyle:{"z-index":"1000"}},[_c('v-spacer'),_c('v-btn',{attrs:{"height":"50","color":_vm.color,"elevation":"1","dark":"","outlined":""},on:{"click":_vm.no_Click}},[_c('h3',{staticClass:"font-weight-bold"},[_vm._v("キャンセル")])]),_c('v-btn',{attrs:{"height":"50","color":_vm.color,"elevation":"1","outlined":"","dark":""},on:{"click":_vm.yes_Click}},[(
            _vm.title == '新規登録' ||
            _vm.title == '新規登録（管理者）' ||
            _vm.title == '新規登録（ユーザー）' ||
            _vm.title == '新規登録（メーカー）'
          )?_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" 登録 ")]):_vm._e(),(
            _vm.title == '編集' ||
            _vm.title == '編集（管理者）' ||
            _vm.title == '編集（ユーザー）' ||
            _vm.title == '編集（メーカー）'
          )?_c('h3',{staticClass:"font-weight-bold"},[_vm._v(" 保存 ")]):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }