<!--
    画面名：履歴検索詳細画面
-->
<template>
  <div>
    <!-- メーカーダイアログ -->
    <detailDialog ref="detailDialog">
      <v-container>
        <!-- 基本情報 -->
        <div class="mt-3">
          <v-row>
            <v-col cols="12">
              <h2>基本情報</h2>
            </v-col>
          </v-row>

          <!-- 状態 -->
          <v-row class="" align="center">
            <v-col cols="4">
              <span style="font-size: 18px">状態</span>
            </v-col>
            <v-col cols="7">
              <span style="font-size: 18px">{{ history.status_cd }}</span>

              <v-chip
                label
                outlined
                color="black"
                class="ml-5 mb-2"
                v-if="history.valid_for_status == '期限切れ'"
              >
                <v-icon color=""> mdi-file-cancel </v-icon>
                <h3 class="font-weight-medium ml-1">
                  {{ history.valid_for_status }}
                </h3>
              </v-chip>
              <v-chip
                label
                color="amber lighten-3"
                class="ml-5 mb-2"
                v-else-if="history.valid_for_status != ''"
                :style="{ border: '1px solid black !important' }"
              >
                <v-icon color=""> mdi-file-alert-outline </v-icon>
                <h3 class="font-weight-medium ml-1">
                  {{ history.valid_for_status }}
                </h3>
              </v-chip>
            </v-col>
          </v-row>

          <!-- 異常終了理由 -->
          <v-row class="mt-n4" v-if="history.error_reason">
            <v-col cols="4">
              <span style="font-size: 18px">異常終了理由</span>
            </v-col>
            <v-col cols="7">
              <span style="font-size: 18px; color: red">
                {{ history.error_reason }}
              </span>
            </v-col>
          </v-row>

          <!-- ファイル名-->
          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">ファイル名</span>
            </v-col>
            <v-col cols="7">
              <span style="font-size: 18px">{{ history.upload_file_uri }}</span>
            </v-col>
          </v-row>

          <!-- 小売名 -->
          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">小売名</span>
            </v-col>
            <v-col cols="7">
              <span style="font-size: 18px">{{
                history.input_layout_.layout_nm === "-" ? "-" :
                history.input_layout_.layout_nm.split("【")[1].split("】")[0]
              }}</span>
            </v-col>
          </v-row>

          <!-- レイアウト名 -->
          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">レイアウト名</span>
            </v-col>
            <v-col cols="7">
              <span style="font-size: 18px">{{
                history.input_layout_.layout_nm === "-" ? "-" :
                history.input_layout_.layout_nm.split("】")[1]
              }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="mt-10">
          <!-- 取込情報 -->
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="12">
                  <h2>取込情報</h2>
                </v-col>
              </v-row>

              <!-- 取込番号 -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">取込番号</span>
                </v-col>
                <v-col cols="5">
                  <span style="font-size: 18px">{{ history.upload_no }}</span>
                </v-col>
              </v-row>

              <!-- 取込日時 -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">取込日時</span>
                </v-col>
                <v-col cols="5">
                  <span style="font-size: 18px">{{ history.upload_at }}</span>
                </v-col>
              </v-row>

              <!-- アップロードユーザー -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">アップロードユーザー</span>
                </v-col>
                <v-col cols="5">
                  <span style="font-size: 18px">{{
                    history.upload_user.user_nm
                  }}</span>
                </v-col>
              </v-row>
            </v-col>

            <!-- 取込件数・重複件数・不整合件数 -->
            <v-col cols="5">
              <v-data-table
                :headers="shownHeaders"
                :items="upload_info"
                hide-default-footer
                hide-default-header
                dense
                outlined
                class="mt-5 custom-table"
                :items-per-page="-1"
              >
                <template v-slot:[`item.key`]="{ item }">
                  <td style="background-color: #e3f2fd">{{ item.key }}</td>
                </template>

                <template v-slot:[`item.value`]="{ item }">
                  <!-- 不整合件数が0より大きい場合  -->
                  <template v-if="item.key === '不整合件数' && item.value != 0">
                    <td style="text-align: right; background-color: #fce4ec">
                      <router-link
                        :to="{name: 'u-05', query: {history_id: history.id}}"
                        target="_blank">
                        {{ item.value }}
                      </router-link>
                    </td>
                  </template>

                  <!-- 重複件数が0より大きい場合  -->
                  <template
                    v-else-if="item.key === '重複件数' && item.value != 0"
                  >
                    <td style="text-align: right; background-color: #fce4ec">
                      <router-link
                        :to="{name: 'u-06', query: {history_id: history.id}}"
                        target="_blank">
                        {{ item.value }}
                      </router-link>
                    </td>
                  </template>

                  <template v-else>
                    <td style="text-align: right">
                      {{ item.value }}
                    </td>
                  </template>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>

        <!-- クレンジング情報 -->
        <div class="mt-10">
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="12">
                  <h2>クレンジング情報</h2>
                </v-col>
              </v-row>

              <!-- クレンジング番号 -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">クレンジング番号</span>
                </v-col>
                <v-col cols="5">
                  <span style="font-size: 18px">{{
                    history.cleansing_no
                  }}</span>
                </v-col>
              </v-row>

              <!-- クレンジング日時 -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">クレンジング日時</span>
                </v-col>
                <v-col cols="5">
                  <span style="font-size: 18px">{{
                    history.cleansing_at
                  }}</span>
                </v-col>
              </v-row>

              <!-- クレンジングオプション -->
              <v-row class="mt-n4">
                <v-col cols="7">
                  <span style="font-size: 18px">オプション</span>
                </v-col>
                <v-col cols="5">
                  <div style="font-size: 18px" v-if="history.exclude_error">
                    不整合を除外
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="5">
              <v-data-table
                :headers="shownHeaders"
                :items="cleansing_info"
                hide-default-footer
                hide-default-header
                dense
                outlined
                class="mt-5 custom-table"
                :items-per-page="-1"
              >
                <template v-slot:[`item.key`]="{ item }">
                  <template
                    v-if="
                      item.key === '店舗未照合' || item.key === '商品未照合'
                    "
                  >
                    <td style="background-color: #e3f2fd; text-align: center">
                      {{ item.key }}
                    </td>
                  </template>

                  <template v-else>
                    <td style="background-color: #e3f2fd">
                      {{ item.key }}
                    </td>
                  </template>
                </template>

                <template v-slot:[`item.value`]="{ item }">
                  <!-- 店舗未照合件数が0より大きい場合 -->
                  <td style="text-align: right">
                    <template
                      v-if="item.key === '店舗未照合' && item.value != 0"
                    >
                      <router-link
                        :to="{name: 'u-09', query: {history_id: history.id}}"
                        target="_blank">
                        {{ item.value }}
                      </router-link>
                    </template>

                    <!-- 商品未照合件数が0より大きい場合 -->
                    <template
                      v-else-if="item.key === '商品未照合' && item.value != 0"
                    >
                      <router-link
                        :to="{name: 'u-08', query: {history_id: history.id}}"
                        target="_blank">
                        {{ item.value }}
                      </router-link>
                    </template>

                    <template v-else>
                      {{ item.value }}
                    </template>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>

        <!-- ダウンロード情報 -->
        <div class="mt-10">
          <v-row>
            <v-col cols="12">
              <h2>ダウンロード情報</h2>
            </v-col>
          </v-row>

          <!-- ダウンロード日時 -->
          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">ダウンロード日時</span>
            </v-col>
            <v-col cols="3">
              <span style="font-size: 18px">{{ history.download_at }}</span>
            </v-col>

            <!-- 出力件数 -->
            <v-col cols="4">
              <v-data-table
                :headers="shownHeaders"
                :items="output_info"
                hide-default-footer
                hide-default-header
                dense
                outlined
                class="custom-table"
                :items-per-page="-1"
              >
                <template v-slot:[`item.key`]="{ item }">
                  <td style="background-color: #e3f2fd">{{ item.key }}</td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!-- ダウンロードユーザー -->
          <v-row class="mt-n6">
            <v-col cols="4">
              <span style="font-size: 18px">ダウンロードユーザー</span>
            </v-col>
            <v-col cols="3">
              <span style="font-size: 18px">{{
                history.download_user.user_nm
              }}</span>
            </v-col>
          </v-row>

          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">ダウンロードレイアウト</span>
            </v-col>
            <v-col cols="5">
              <span style="font-size: 18px">{{
                history.output_layout_.layout_nm
              }}</span>
            </v-col>
          </v-row>

          <!-- ダウンロードオプション -->
          <v-row class="mt-n4">
            <v-col cols="4">
              <span style="font-size: 18px">オプション</span>
            </v-col>
            <v-col cols="5">
              <v-row no-gutters v-if="history.exclude_unmatch_item">
                <span style="font-size: 18px"> 未照合商品を除外 </span>
              </v-row>
              <v-row
                no-gutters
                class="mt-2"
                v-if="history.exclude_unmatch_store"
              >
                <span style="font-size: 18px"> 未照合店舗を除外 </span>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </detailDialog>
  </div>
</template>

<script>
import detailDialog from '@/components/DetailDialog.vue';

export default {
  components: {
    detailDialog,
  },
  data: () => ({
    history: {},
    dialog: false,
    shownHeaders: [
      {
        text: '',
        align: 'start',
        value: 'key',
        class: '',
      },
      {
        text: '',
        align: 'end',
        value: 'value',
        class: '',
      },
    ],
  }),
  methods: {
    // ダイアログを開く
    open(history) {
      this.history = history;
      this.$refs.detailDialog.open(850, 750, '');
    },

    // 不整合件数リンククリック時
    open_error() {
      this.$router.push({ query: { history_id: 1 } });
    },
    // 重複件数リンククリック時
    open_duplicate() {
      this.$router.push({ name: 'u-06' });
    },
    // 未照合商品件数リンククリック時
    open_unmatch_item() {
      this.$router.push({ name: 'u-08' });
    },
    // 未照合店舗件数リンククリック時
    open_unmatch_store() {
      this.$router.push({ name: 'u-09' });
    },
  },
  computed: {
    upload_info() {
      return [
        { key: '取込件数', value: this.history.input_count },
        { key: '不整合件数', value: this.history.error_count },
        { key: '重複件数', value: this.history.duplicate_count },
      ];
    },
    cleansing_info() {
      return [
        { key: 'クレンジング件数', value: this.history.cleansing_count },
        { key: '未照合件数', value: this.history.unmatch_count },
        { key: '店舗未照合', value: this.history.unmatch_count_store },
        { key: '商品未照合', value: this.history.unmatch_count_item },
      ];
    },
    output_info() {
      return [
        { key: '出力件数', value: this.history.output_count },
      ];
    },
  },
};
</script>

<style scoped>
.custom-table {
  border: 1px solid rgb(216, 216, 216);
}
</style>
