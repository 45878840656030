<!--
    トップ画面のテンプレート
-->
<template>
  <div id="MainTemplate" style="min-width: 1830px">
    <CallApi ref="CallApi" />
    <v-app-bar dark color="#1C84AC" app clipped-left>
      <v-toolbar-title>
        <router-link :to="{name: 'u-02'}" style="color: white; text-decoration: none;">
          POSデータクレンジングサービス
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="mr-5 caption" outlined>
        <span style="font-size: 14px">
          {{login_user_maker_nm}}  {{ login_user_nm }}
        </span>

        <br />
        <span
          v-if="proxy_login_user_nm"
          style="font-size: 14px"
        >
          代理ログイン：{{proxy_login_user_comp_nm}} {{ proxy_login_user_nm }}
        </span>
      </div>
      <v-btn @click="$refs.ManualDialog.open(manual_list)" outlined class="mr-2">
        <v-icon size="30">mdi-book-open</v-icon>
        <h4>マニュアル</h4>
      </v-btn>
      <v-btn @click="logout_click" outlined>
        <v-icon size="30">mdi-logout</v-icon>
        <h4>ログアウト</h4>
      </v-btn>
    </v-app-bar>

    <router-view />

    <ManualDialog ref="ManualDialog" />
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';
import ManualDialog from '@/components/ManualDialog.vue';

export default {
  components: {
    CallApi,
    ManualDialog,
  },
  name: 'TopTemplate',
  data: () => ({
    login_user_nm: '',
    login_user_maker_nm: '',
    proxy_login_user_nm: '',
    proxy_login_user_comp_nm: '',
    manual_list: [],
  }),

  // 初期画面表示用の値を取得する
  mounted() {
    this.$refs.CallApi.callApi(
      '/api/main_template/get_initial_info',
      {},
      (response) => {
        this.login_user_nm = response.data.login_user_nm;
        this.login_user_maker_nm = response.data.login_user_maker_nm;
        this.proxy_login_user_nm = response.data.proxy_login_user_nm;
        this.proxy_login_user_comp_nm = response.data.proxy_login_user_comp_nm;
        this.manual_list = response.data.manual_list;
      },
      () => {
        // エラーハンドリングは共通処理
      },
      {
        showError: false,
      },
    );
  },
  methods: {
    logout_click() {
      this.$refs.CallApi.callApi(
        '/api/u-01/logout',
        {},
        () => {
          localStorage.accessToken = null;
          localStorage.refreshToken = null;
          this.$router.push({ name: 'u-01' });
        },
        () => {
          // エラーハンドリングは共通処理
        },
      );
    },
  },

};
</script>
