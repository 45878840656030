<!--
    画面ID：ConfirmDialog
    画面名：共通処理：確認メッセージの表示処理
-->
<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card color="grey lighten-3">
        <v-card-text>
          <v-row justify="center" class="pt-13 mb-2">
            <v-icon x-large color="deep-orange" v-if="showIcon">mdi-alert</v-icon>
          </v-row>
          <v-row justify="center">
            <div class="text-h5 px-10 pb-10" style="white-space: pre-line;" v-if="true">
              {{message}}
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8 pt-5 white">
          <v-btn
            color="grey lighten-3"
            large
            class="mx-auto mr-4"
            width="25%"
            height="50"
            @click="no_Click"
          >
            <div class="text-h6">いいえ</div>
          </v-btn>

          <v-btn
            color="black"
            large
            dark
            class="mx-auto ml-4"
            width="25%"
            height="50"
            @click="yes_Click"
          >
            <div class="text-h6">はい</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'confirmDialog',
  data: () => ({
    dialog: false,
    message: null,
    showIcon: true,
  }),
  methods: {
    open(message, yesCallback, noCallback, showIcon = true) {
      this.dialog = true;
      this.message = message;
      this.yesCallback = yesCallback;
      this.noCallback = noCallback;
      this.showIcon = showIcon;
    },
    yes_Click() {
      if (this.yesCallback) {
        this.yesCallback();
      }
      this.dialog = false;
    },
    no_Click() {
      if (this.noCallback) {
        this.noCallback();
      }
      this.dialog = false;
    },
  },
};
</script>
