<!--
    画面名：ファイル内重複確認画面
-->
<template>
  <div>
    <v-main class="grey lighten-3 pa-0">
      <CallApi ref="CallApi"></CallApi>
      <v-container fluid class="px-15">
        <v-row class="pt-5">
          <h2>ファイル内重複確認画面</h2>
        </v-row>
        <!-- タイトル下のカード -->
        <v-row class="ml-n3 pr-3 mt-5">
          <headerPanel label="取込情報">
            <headCard
              :upload_no="upload_no"
              :upload_at="upload_at"
              :retail_id="retail_id"
              :layout_nm="layout_nm"
              :file_nm="file_nm"
              :input_count="input_count"
              :duplicate_count="duplicate_count"
            />
          </headerPanel>
        </v-row>

        <!-- 制限表示 -->
        <v-row
          justify="end"
          v-if="list_count > list_count_max"
          class="mt-10 mb-n8"
        >
          <overLimitMessage :limit="list_count_max" />
        </v-row>

        <!-- テーブル部分 -->
        <tableParts :headers="headers" :duplicate_list="duplicate_list" />

        <CopyRight />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';
import headerPanel from '@/components/HeaderPanel.vue';
import overLimitMessage from '@/components/OverLimitMessage.vue';
import CallApi from '@/components/CallApi.vue';
import tableParts from '@/views/u-06/TableParts.vue';
import headCard from '@/views/u-06/HeadCard.vue';

export default {
  components: {
    headCard,
    tableParts,
    headerPanel,
    overLimitMessage,
    CallApi,
    CopyRight,
  },
  data: () => ({
    upload_no: '',
    upload_at: '',
    retail_id: '',
    layout_nm: '',
    file_nm: '',
    input_count: 0,
    duplicate_count: 0,
    duplicate_list: [],
    headers: [],

    list_count: 0,
    list_count_max: 0,
  }),

  // 初期画面出力
  mounted() {
    // API「get_initial_info」を呼び、一覧を得る。
    this.$refs.CallApi.callApi(
      'api/u-06/get_initial_info',
      {
        history_id: this.$route.query.history_id,
      },
      (response) => {
        // APIが正常終了した場合
        this.upload_no = response.data.upload_no;
        this.upload_at = response.data.upload_at;
        this.retail_id = response.data.retail_id;
        this.layout_nm = response.data.layout_nm;
        this.file_nm = response.data.file_nm;
        this.input_count = response.data.input_count;
        this.duplicate_count = response.data.duplicate_count;
        this.duplicate_list = response.data.duplicate_list;

        this.headers = this.create_headers();
        this.get_max_and_now_recode_count(response);
      },
      () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
      },
    );
  },
  methods: {
    create_headers() {
      if (this.duplicate_list.length > 0) {
        // 一行目の項目からヘッダーを生成する
        return Object.keys(this.duplicate_list[0])
          .filter((key) => key !== 'キー項目名') // 'キー項目名'を除外
          .map((key) => ({
            text: key, value: key, width: key === '行' ? 80 : 250, class: 'white--text', // '行'の場合には80、それ以外は250と設定
          }));
      }
      // エラー項目がない場合、空のヘッダーを返す
      return [];
    },
    get_max_and_now_recode_count(response) {
      this.list_count = response.data.list_count;
      this.list_count_max = response.data.list_count_max;
    },
  },
};
</script>
