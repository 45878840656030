<!--
    画面名：履歴検索画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <!-- ダイアログ -->
    <confirmDialog ref="confirmDialog" />
    <loadingDialog ref="loadingDialog" />
    <historyTableDetailDialog ref="historyTableDetailDialog" />
    <createDownloadFileDialog ref="createDownloadFileDialog" />

    <!-- 底部 -->
    <footerParts
      :selectedItems="selectedItems"
      :use_cleansing_immediate="use_cleansing_immediate"
      :output_layout_list="output_layout_list"
      v-on:search="search"
      v-on:delete_confirm="delete_confirm"
      v-on:create_file="create_file"
    />

    <v-main class="grey lighten-3 pa-0">
      <v-container fluid class="px-15">
        <v-row class="pt-5">
          <h2>履歴検索画面</h2>
        </v-row>
        <v-row class="ml-n3 pr-3 mt-5">
          <!-- 検索条件欄 -->
          <headerPanel label="検索条件">
            <searchParts
              :history_valid_days="history_valid_days"
              :input_layout_list="input_layout_list"
              :status_list="status_list"
              v-on:search="search"
              v-on:GetSearchParam="GetSearchParam"
              ref="searchParts"
            />
          </headerPanel>
        </v-row>

        <!-- 制限表示 -->
        <v-row
          justify="end"
          v-if="list_count > list_count_max"
          class="mt-10 mb-n8"
        >
          <overLimitMessage :limit="list_count_max" />
        </v-row>

        <!-- 一覧 -->
        <tableParts
          :is_admin="is_admin"
          :history_list="history_list"
          v-on:open_datail="open_datail"
          v-on:search="search"
          v-on:edit_open="edit_open"
          v-on:selected_changed="selected_changed"
          ref="tableParts"
        />

        <v-row class="mt-5"> </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import '../../assets/styles.css';
import confirmDialog from '@/components/ConfirmDialog.vue';
import loadingDialog from '@/components/LoadingDialog.vue';
import headerPanel from '@/components/HeaderPanel.vue';
import CallApi from '@/components/CallApi.vue';
import tableParts from '@/views/u-04/TableParts.vue';
import searchParts from '@/views/u-04/SearchParts.vue';
import footerParts from '@/views/u-04/FooterParts.vue';
import historyTableDetailDialog from '@/views/u-04/dialog/HistoryTableDetailDialog.vue';
import createDownloadFileDialog from '@/views/u-04/dialog/CreateDownloadFileDialog.vue';
import overLimitMessage from '@/components/OverLimitMessage.vue';

export default {
  components: {
    confirmDialog,
    loadingDialog,
    headerPanel,
    searchParts,
    tableParts,
    footerParts,
    historyTableDetailDialog,
    createDownloadFileDialog,
    CallApi,
    overLimitMessage,
  },
  data: () => ({
    selectedItems: [],

    is_admin: null,
    history_valid_days: 20,
    input_layout_list: [],
    status_list: [],
    use_cleansing_immediate: true,
    output_layout_list: [],
    history_list: [],

    upload_at_from: null,
    upload_at_to: null,
    cleansing_at_from: null,
    cleansing_at_to: null,
    selected_status_cd_list: null,
    selected_layout_id_list: null,
    file_nm: '',
    match: true,
    unmatch: true,
    over_valid: true,

    list_count: 0,
    list_count_max: 0,

  }),

  // 初期画面出力
  mounted() {
    // API「get_initial_info」を呼び、一覧を得る。
    this.$refs.CallApi.callApi(
      'api/u-04/get_initial_info',
      {},
      (response) => {
        // APIが正常終了した場合
        this.is_admin = response.data.is_admin;
        this.history_valid_days = response.data.history_valid_days;
        this.input_layout_list = response.data.input_layout_list;
        this.status_list = response.data.status_list;
        this.use_cleansing_immediate = response.data.use_cleansing_immediate;
        this.output_layout_list = response.data.output_layout_list;
        this.history_list = response.data.history_list.map((historyItem) => ({
          ...historyItem,
          upload_at: this.formatDatetime(historyItem.upload_at),
          cleansing_at: this.formatDatetime(historyItem.cleansing_at),
          download_at: this.formatDatetime(historyItem.download_at),
        }));
        this.get_max_and_now_recode_count(response);
      },

      () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
      },
    );
  },

  methods: {
    // 検索コンポーネントの検索機能を呼び、検索結果を取得する。(リロード時にも使用)
    async search() {
      // 選択行があれば解除
      this.$refs.tableParts.clear_select();
      this.selectedItems = [];
      try {
        // 検索パラメータ取得(検索欄を一度も開いていない場合はエラーになるため無視)
        this.$refs.searchParts.send_param_parent();
      } catch (e) {
        //
      }
      await this.$refs.CallApi.callApi(
        'api/u-04/get_search_result',
        {
          upload_at_from: this.upload_at_from,
          upload_at_to: this.upload_at_to,
          cleansing_at_from: this.cleansing_at_from,
          cleansing_at_to: this.cleansing_at_to,
          status_cd_list: this.selected_status_cd_list,
          layout_id_list: this.selected_layout_id_list,
          file_nm: this.file_nm,
          match: this.match,
          unmatch: this.unmatch,
          over_valid: this.over_valid,
        },
        (response) => {
          this.history_list = response.data.history_list.map((historyItem) => ({
            ...historyItem,
            upload_at: this.formatDatetime(historyItem.upload_at),
            cleansing_at: this.formatDatetime(historyItem.cleansing_at),
            download_at: this.formatDatetime(historyItem.download_at),
          }));
          this.get_max_and_now_recode_count(response);
        },
        () => {
        },
      );
    },

    // 詳細画面を開く
    open_datail(history) {
      this.$refs.historyTableDetailDialog.open(history);
    },
    create_file() {
      this.$refs.createDownloadFileDialog.open();
    },
    immediate_cleansing() {
      this.$refs.confirmDialog.open('クレンジング処理を開始します。\n よろしいですか？');
    },
    searchExecute() {
      this.status = this.$refs.searchParts.status;
    },
    delete_confirm() {
      this.$refs.confirmDialog.open('');
    },
    GetSearchParam(searchList) {
      // 検索条件を子コンポーネントから受け取る
      const [
        uploadAtFrom,
        uploadAtTo,
        cleansingAtFrom,
        cleansingAtTo,
        selectedStatusCodeList,
        selectedLayoutIdList,
        fileNm,
        match,
        unmatch,
        ocerValid,
      ] = searchList;

      this.upload_at_from = uploadAtFrom;
      this.upload_at_to = uploadAtTo;
      this.cleansing_at_from = cleansingAtFrom;
      this.cleansing_at_to = cleansingAtTo;
      this.selected_status_cd_list = selectedStatusCodeList;
      this.selected_layout_id_list = selectedLayoutIdList;
      this.file_nm = fileNm;
      this.match = match;
      this.unmatch = unmatch;
      this.over_valid = ocerValid;
    },

    // datatableのチェックボックスの状態変化時、フッターにその値を渡す（ボタン比活性制御用）
    selected_changed(newValue) {
      this.selectedItems = newValue;
    },

    // datetimeを文字列に変換する
    formatDatetime(datetime) {
      if (datetime === null) {
        return datetime;
      }
      const date = new Date(datetime);
      return date.toLocaleString('ja-JP', {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false,
      });
    },
    get_max_and_now_recode_count(response) {
      this.list_count = response.data.list_count;
      this.list_count_max = response.data.list_count_max;
    },
  },

};
</script>
