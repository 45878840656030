  <!--
    画面ID：ErrorDialog
    画面名：共通処理：エラーメッセージの表示処理
  -->
  <template>
    <div>
      <v-dialog v-model="dialog" max-width="600" persistent>
        <v-card color="grey lighten-3">
          <v-card-text>
            <v-row justify="center" class="pt-13 mb-2">
              <v-icon x-large color="deep-orange">mdi-information</v-icon>
            </v-row>
            <v-row justify="center">
              <div style="white-space: pre-line" class="text-h5 px-10 pb-10" v-if="true">
                {{ message }}
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions class="py-10 white">
            <v-row justify="center">
              <v-btn
                color="grey lighten-3"
                large
                class="mx-auto"
                width="25%"
                height="50"
                @click="close()"
              >
                <div class="text-h6">閉じる</div>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    </template>

<script>
export default {
  name: 'ErrorDialog',
  data: () => ({
    dialog: false,
    message: null,
    callback: null,
  }),
  methods: {
    open(message, callback) {
      this.dialog = true;
      this.message = message;
      this.callback = callback;
    },
    close() {
      this.dialog = false;
      if (this.callback) {
        this.callback();
      }
    },
  },
};
</script>
