<!--
    画面ID：
    画面名：共通処理：件数超過メッセージ
-->
<template>
  <span style="color: red">
    表示件数が{{limit}}件を超えました。<br />
    先頭の{{limit}}件のみ表示しています。
  </span>
</template>

<script>

export default {
  props: {
    limit: {
      type: Number,
    },
  },
};
</script>
