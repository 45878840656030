<!--
    画面名：ファイル内重複確認画面
-->
<template>
  <v-row class="ml-n6 mt-8" align="center" justify="center">
    <v-col cols="12">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="duplicate_list"
        class="elevation-1 duplicate-table-column-fixed"
        hide-default-footer
        :height="$vuetify.breakpoint.height - 390"
        :items-per-page="1000"
      >
        <template
          v-for="(header, i) in headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <td
            :key="i"
            :class="
              item['キー項目名'].includes(header.value) ? 'duplicate-cell' : ''
            "
          >
            <!-- 要素が長い場合に省略するように設定 -->
            <CustomTooltip :maxWidth="'250px'" :content="item[header.value]" />
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import CustomTooltip from '@/components/CustomTooltip.vue';

export default {
  components: {
    CustomTooltip,
  },
  methods: {

  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    duplicate_list: {
      type: Array,
      required: true,
    },
  },

  data: () => ({

  }),
};
</script>

<style scoped>
.duplicate-cell {
  background-color: pink !important;
}
</style>
