  <!--
    画面ID：ManualDialog
    画面名：共通処理：マニュアルの表示処理
  -->
  <template>
  <div>
    <CallApi ref="CallApi"></CallApi>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark>
          <v-icon>mdi-book-open</v-icon>
          <div class="text-h6 pa-5">マニュアル</div>
        </v-toolbar>
        <v-card-text>
          <v-simple-table class="mt-4 elevation-1">
            <template v-slot:default>
              <tbody>
                <tr v-for="m in manualList" v-bind:key="m.manual_id">
                  <td>{{ m.manual_nm }}</td>
                  <td>
                    <v-btn small text color="" class="" @click="openManual(m.manual_id)">
                      <v-icon color="#1C84AC"> mdi-download </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row justify="center" class="mx-8">
            <v-btn
              color="blue-grey lighten-1"
              large
              dark
              class="mx-auto"
              width="30%"
              height="50"
              @click="close()"
            >
              <h3>閉じる</h3>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';

export default {

  name: 'ManualDialog',
  components: {
    CallApi,
  },
  data: () => ({
    dialog: false,
    manualList: null,
    callback: null,
  }),
  methods: {
    open(manualList, callback) {
      this.dialog = true;
      this.manualList = manualList;
      this.callback = callback;
    },
    close() {
      this.dialog = false;
      if (this.callback) {
        this.callback();
      }
    },
    openManual(key) {
      this.$refs.CallApi.callApi(
        'api/main_template/get_manual_download_url',
        {
          key,
        },
        (response) => {
          const a = document.createElement('a');
          a.href = response.data.download_url;
          a.target = '_blank';
          const evt = document.createEvent('MouseEvents');
          evt.initEvent('click', false, true);
          a.dispatchEvent(evt);
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
  },
};
</script>
