<!--
    画面名：未照合商品確認画面
-->
<template>
  <div>
    <!-- フッター -->
    <footerParts v-on:get_presigned_url="get_presigned_url" />

    <v-main class="grey lighten-3 pa-0">
      <CallApi ref="CallApi"></CallApi>
      <v-container fluid class="px-15">
        <v-row class="pt-5">
          <h2>未照合商品確認画面</h2>
        </v-row>
        <!-- タイトル下のカード -->
        <v-row class="ml-n3 pr-3 mt-5">
          <headerPanel label="取込情報">
            <headCard
              :cleansing_no="cleansing_no"
              :upload_at="upload_at"
              :retail_id="retail_id"
              :layout_nm="layout_nm"
              :file_nm="file_nm"
            />
          </headerPanel>
        </v-row>

        <!-- 制限表示 -->
        <v-row
          justify="end"
          v-if="list_count > list_count_max"
          class="mt-10 mb-n8"
        >
          <overLimitMessage :limit="list_count_max" />
        </v-row>

        <!-- テーブル部分 -->
        <tableParts
          :headers="headers"
          :unmatch_item_filter_list="unmatch_item_filter_list"
          :selected_filter="selected_filter"
          :unmatch_item_list="unmatch_item_list"
          v-on:select_filter="select_filter"
          ref="tableParts"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import headerPanel from '@/components/HeaderPanel.vue';
import CallApi from '@/components/CallApi.vue';
import overLimitMessage from '@/components/OverLimitMessage.vue';
import footerParts from '@/views/u-08/FooterParts.vue';
import headCard from '@/views/u-08/HeadCard.vue';
import tableParts from '@/views/u-08/TableParts.vue';

export default {
  components: {
    footerParts,
    headCard,
    tableParts,
    headerPanel,
    CallApi,
    overLimitMessage,
  },

  data: () => ({
    cleansing_no: '',
    upload_at: '',
    retail_id: '',
    layout_nm: '',
    file_nm: '',
    unmatch_item_filter_list: [],
    unmatch_item_list: [],
    list_count: 0,
    list_count_max: 0,
    headers: [],

  }),
  // 初期画面出力
  mounted() {
    // API「get_initial_info」を呼び、一覧を得る。
    this.$refs.CallApi.callApi(
      'api/u-08/get_initial_info',
      {
        history_id: this.$route.query.history_id,
      },
      (response) => {
        // APIが正常終了した場合
        this.cleansing_no = response.data.cleansing_no;
        this.upload_at = response.data.upload_at;
        this.retail_id = response.data.retail_id;
        this.layout_nm = response.data.layout_nm;
        this.file_nm = response.data.file_nm;
        this.unmatch_item_filter_list = response.data.unmatch_item_filter_list;
        this.unmatch_item_list = response.data.unmatch_item_list;
        this.list_count = response.data.list_count;
        this.list_count_max = response.data.list_count_max;

        this.headers = this.create_headers(response.data.unmatch_item_list);
      },
      () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
      },
    );
  },
  methods: {
    create_headers(unmatchItemList) {
      if (unmatchItemList.length > 0) {
        // 一行目の項目からヘッダーを生成する
        return Object.keys(unmatchItemList[0])
          .filter((key) => key.trim() !== '' && key !== 'TD商品マスタ照合結果' && key !== 'メーカ商品マスタ照合結果') // 空白、TD商品マスタ、メーカー商品マスタ照合結果のキーをフィルタリング
          .map((key) => ({
            text: key,
            value: key,
            width: key === '商品名' ? 300 : 240, // 商品名の場合はwidthを300に、それ以外は220に設定
            class: 'white--text',
          }));
      }
      return [];
    },

    // 絞り込み選択肢選択時
    select_filter(unmatchedItemFilterIndex) {
      this.$refs.CallApi.callApi(
        'api/u-08/get_filter_recode',
        {
          history_id: this.$route.query.history_id,
          unmatched_item_filter_index: unmatchedItemFilterIndex,
        },
        (response) => {
        // APIでエラーが発生しなかった場合
          this.unmatch_count_item = response.data.unmatch_count_item;
          this.unmatch_item_list = response.data.unmatch_item_list;

          this.list_count = response.data.list_count;
          this.list_count_max = response.data.list_count_max;

          this.get_unmatch_result(response);
        },
        () => {
        // APIでエラーが発生した場合
        },
      );
    },

    // エクスポートボタン押下時
    get_presigned_url() {
      this.$refs.tableParts.get_presigned_url();
    },

  },
};
</script>
