<!--
    画面名：未照合店舗確認画面
-->
<template>
  <v-footer
    app
    fixed
    :padless="true"
    :inset="true"
    color="blue-grey lighten-4"
    class="pa-2 pl-10"
  >
    <v-container fluid>
      <v-row no-gutters>
        <v-btn
          height="80"
          elevation="2"
          class=""
          style="border: thin solid #1c84ac"
          @click="export_click"
        >
          <v-icon size="30" class="mr-3" color="#1C84AC"
            >mdi-file-export</v-icon
          >
          <h2 style="color: #1c84ac">エクスポート</h2>
        </v-btn>
      </v-row>

      <CopyRight />
    </v-container>
  </v-footer>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';

export default {
  components: {
    CopyRight,
  },
  methods: {
    export_click() {
      this.$emit('get_presigned_url');
    },
  },
};
</script>
