<!--
    画面ID：headerPanel
    画面名：共通処理：検索条件欄
-->
<template>

  <v-expansion-panels focusable>
    <CallApi ref="CallApi"></CallApi>
    <v-expansion-panel class="grey lighten-5" @change="toggleScroll">
      <v-expansion-panel-header>
        <h3>{{ label }}</h3>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="px-0 mx-n6">
        <div class="ml-13">
          <slot />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import CallApi from '@/components/CallApi.vue';

export default {
  components: {
    CallApi,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  data: () => ({
  }),
  mounted() {
  },
  methods: {
    toggleScroll(isOpen) {
      if (isOpen) {
        // The panel is open, enable scroll
        document.body.style.overflow = '';
      } else {
        // The panel is closed, disable scroll
        document.body.style.overflow = 'hidden';
      }
    },
  },
};
</script>
