<!--
    画面名：ユーザー管理画面
-->
<template>
  <div>
    <confirmDialog ref="confirmDialog" />
    <CallApi ref="CallApi"></CallApi>
    <v-row class="ml-n6 mt-8" align="center" justify="center">
      <v-col cols="12">
        <v-data-table
          v-model="selected_items"
          :headers="headers"
          :items="user_list"
          item-key="id"
          class="elevation-1"
          show-select
          hide-default-footer
          :height="$vuetify.breakpoint.height - 390"
          :items-per-page="-1"
        >
          <!-- 編集アイコン挿入 -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  color="#1C84AC"
                  @click="editItem(item)"
                  outlined
                  elevation="2"
                >
                  <v-icon> mdi-pen </v-icon>
                </v-btn>
              </template>
              <span>編集</span>
            </v-tooltip>
          </template>
          <!-- メールアドレス -->
          <template v-slot:[`item.login_username`]="{ item }">
            <CustomTooltip :maxWidth="'400px'" :content="item.login_username" />
          </template>

          <!-- ユーザー名 -->
          <template v-slot:[`item.user_nm`]="{ item }">
            <CustomTooltip :maxWidth="'300px'" :content="item.user_nm" />
          </template>

          <!-- 権限 -->
          <template v-slot:[`item.role.role_nm`]="{ item }">
            <CustomTooltip :maxWidth="'200px'" :content="item.role.role_nm" />
          </template>

          <!-- 使用可能レイアウト -->
          <template v-slot:[`item.input_layout_list`]="{ item }">
            <CustomTooltip
              :maxWidth="'450px'"
              :content="
                item.input_layout_list
                  .map((layout) => layout.layout_nm)
                  .join(', ')
              "
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CallApi from '@/components/CallApi.vue';
import confirmDialog from '@/components/ConfirmDialog.vue';
import CustomTooltip from '@/components/CustomTooltip.vue';

export default {
  components: {
    confirmDialog,
    CallApi,
    CustomTooltip,
  },
  props: {
    user_list: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selected_items: [],

    headers: [
      {
        text: 'メールアドレス',
        align: 'start',
        value: 'login_username',
        class: 'white--text',
        width: 400,
      },
      {
        text: 'ユーザー名', value: 'user_nm', class: 'white--text', width: 300,
      },
      {
        text: '権限', value: 'role.role_nm', class: 'white--text', width: 200,
      },
      {
        text: '使用可能レイアウト', value: 'input_layout_list', class: 'white--text', width: 450,
      },
      {
        text: '', value: 'actions', sortable: false, class: 'white--text',
      },
    ],
    shownHeaders: [
      {
        text: '使用可能レイアウト',
        align: 'start',
        value: 'layouts',
        class: 'white--text',
      },
    ],
  }),
  methods: {
    editItem(item) {
      const itemCopy = { ...item };
      this.$emit('edit_open', itemCopy);
    },
    confirm_dialog_open() {
      if (!this.selected_items.length) {
        return;
      }
      this.$refs.confirmDialog.open(
        '選択行を削除しますか？',
        () => {
        // 「はい」の場合
        // 選択行を削除する。
          this.delete_user();
        },
        () => {
        // 「いいえ」の場合は何もしない

        },
      );
    },

    delete_user() {
      // id_listに選択行のidを格納する
      const selectedIdList = this.selected_items.map((item) => item.id);
      this.selected_items = [];

      // API「delete_user」を呼び、選択行を削除する。
      this.$refs.CallApi.callApi(
        'api/u-12/delete_user',
        {
          id_list: selectedIdList,
        },
        () => {
        // APIが正常終了した場合
        // 画面を更新する。
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
  },

  watch: {
    selected_items(newVal) {
      // テーブルの要素を選択しているかどうか親に送信（ボタン非活性制御用）
      if (newVal.length === 0) {
        this.$emit('selected_changed', false);
      } else {
        this.$emit('selected_changed', true);
      }
    },
  },
};
</script>
