<!--
    画面名：ユーザー管理画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <!-- ダイアログ -->
    <userRegisterDialog
      :available_layout_list="available_layout_list"
      :role_list="role_list"
      v-on:search="search"
      ref="userRegisterDialog"
    />

    <!-- 底部 -->
    <footerParts
      :table_item_selected="table_item_selected"
      v-on:remove="remove"
      v-on:register_open="register_open"
    />

    <v-main class="grey lighten-3 pa-0">
      <v-container fluid class="px-15">
        <v-row class="pt-5">
          <h2>ユーザー一覧画面</h2>
        </v-row>
        <v-row class="ml-n3 pr-3 mt-5">
          <!-- 検索欄 -->
          <headerPanel label="検索条件">
            <searchParts
              :role_list="role_list"
              v-on:search="search"
              v-on:InputChange="InputChange"
              ref="searchParts"
            />
          </headerPanel>
        </v-row>

        <!-- 制限表示 -->
        <v-row
          justify="end"
          v-if="user_count > user_count_max"
          class="mt-10 mb-n8"
        >
          <overLimitMessage :limit="user_count_max" />
        </v-row>

        <!-- テーブル -->
        <tableParts
          :user_list="user_list"
          v-on:search="search"
          v-on:edit_open="edit_open"
          v-on:selected_changed="selected_changed"
          ref="tableParts"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import headerPanel from '@/components/HeaderPanel.vue';
import CallApi from '@/components/CallApi.vue';
import searchParts from '@/views/u-12/SearchParts.vue';
import footerParts from '@/views/u-12/FooterParts.vue';
import tableParts from '@/views/u-12/TableParts.vue';
import overLimitMessage from '@/components/OverLimitMessage.vue';
import userRegisterDialog from '@/views/u-12/userRegisterDialog.vue';

export default {
  components: {
    headerPanel,
    searchParts,
    footerParts,
    tableParts,
    userRegisterDialog,
    overLimitMessage,
    CallApi,
  },
  data: () => ({
    table_item_selected: false,

    role_list: [],
    available_layout_list: [],
    user_list: [],
    maker_nm: '',
    login_username: '',
    user_nm: '',
    selected_role: {},
    input_layout_nm: '',

    user_count: 0,
    user_count_max: 0,
  }),
  // 初期画面出力
  mounted() {
    // API「get_initial_info」を呼び、お知らせ一覧を得る。
    this.$refs.CallApi.callApi(
      'api/u-12/get_initial_info',
      {},
      (response) => {
        // APIが正常終了した場合
        this.role_list = response.data.role_list;
        this.available_layout_list = response.data.available_layout_list;
        this.user_list = response.data.user_list;
        this.user_count = response.data.user_count;
        this.user_count_max = response.data.user_count_max;
      },
      () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
      },
    );
  },
  methods: {
    // 検索コンポーネントの検索機能を呼び、検索結果を取得する。(リロード時にも使用)
    async search() {
      await this.$refs.CallApi.callApi(
        'api/u-12/get_search_result',
        {

          login_username: this.login_username,
          user_nm: this.user_nm,
          role_id: this.role_id,
          input_layout_nm: this.input_layout_nm,
        },
        (response) => {
          this.user_list = response.data.user_list;
          this.user_count = response.data.user_count;
          this.user_count_max = response.data.user_count_max;
        },
        () => {
        },
      );
      return this.user_list;
    },
    register_open() {
      this.$refs.userRegisterDialog.register_dialog_open();
    },
    edit_open(item) {
      this.$refs.userRegisterDialog.edit_dialog_open(item);
    },
    remove() {
      this.$refs.tableParts.confirm_dialog_open();
    },
    InputChange(searchList) {
      const [mailAddress, userNm, roleId, inputLayoutNm] = searchList;
      this.login_username = mailAddress;
      this.user_nm = userNm;
      this.role_id = roleId;
      this.input_layout_nm = inputLayoutNm;
    },

    selected_changed(tableItemSelected) {
      this.table_item_selected = tableItemSelected;
    },
  },
};
</script>
