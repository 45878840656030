<!--
    画面ID：FooterParts
    画面名：u-04 底部の部品
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <confirmDialog ref="confirmDialog" />
    <createDownloadFileDialog
      ref="createDownloadFileDialog"
      :output_layout_list="output_layout_list"
    />
    <v-footer
      app
      fixed
      :padless="true"
      :inset="true"
      color="blue-grey lighten-4"
      class="pa-2 pl-10"
      style="min-width: 1500px"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <div>
              <v-btn
                elevation="2"
                height="70"
                width="200"
                class=""
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="cleansing_nightily_click"
                :disabled="isDisabled_nightly"
              >
                <v-icon size="30" color="#1C84AC"
                  >mdi-file-arrow-left-right</v-icon
                >
                <v-icon size="20" color="#1C84AC" class="mt-2"
                  >mdi-clock-time-two</v-icon
                >
                <h3 class="font-weight-bold ml-1" style="color: #1c84ac">
                  クレンジング<br />夜間実行
                </h3>
              </v-btn>

              <v-btn
                elevation="2"
                height="70"
                width="200"
                class="ml-5"
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="cleansing_cancel_click"
                :disabled="isDisabled_cancel"
              >
                <v-icon size="30" color="#1C84AC">mdi-cancel</v-icon>
                <h3 class="font-weight-bold ml-6" style="color: #1c84ac">
                  クレンジング<br />キャンセル
                </h3>
              </v-btn>

              <v-btn
                elevation="2"
                height="70"
                width="200"
                class="ml-5"
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="cleansing_immediate_click()"
                :disabled="isDisabled_immediate"
                v-if="use_cleansing_immediate"
              >
                <v-icon size="30" color="#1C84AC"
                  >mdi-file-arrow-left-right</v-icon
                >
                <v-icon size="25" color="#1C84AC" class="mt-2"
                  >mdi-clock-fast</v-icon
                >
                <h3 class="font-weight-bold ml-1" style="color: #1c84ac">
                  クレンジング<br />即時実行
                </h3>
              </v-btn>

              <v-btn
                elevation="2"
                height="70"
                width="200"
                class="ml-5"
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="create_file_click()"
                :disabled="isDisabled_create_file"
                v-if="use_cleansing_immediate"
              >
                <v-icon size="30" color="#1C84AC">mdi-file-plus</v-icon>
                <h3 class="font-weight-bold" style="color: #1c84ac">
                  ファイル作成
                </h3>
              </v-btn>

              <v-btn
                elevation="2"
                height="70"
                width="200"
                class="ml-5"
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="download_click()"
                :disabled="isDisabled_download"
              >
                <v-icon size="30" color="#1C84AC">mdi-download</v-icon>
                <h3 class="font-weight-bold" style="color: #1c84ac">
                  一括ダウンロード
                </h3>
              </v-btn>

              <v-btn
                elevation="2"
                height="70"
                width="200"
                class="ml-5"
                color=""
                v-bind="attrs"
                v-on="on"
                style="border: thin solid #1c84ac"
                @click="delete_click"
                :disabled="isDisabled_delete"
              >
                <v-icon size="30" color="#1C84AC">mdi-delete</v-icon>
                <h3 class="font-weight-bold" style="color: #1c84ac">
                  履歴削除
                </h3>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <CopyRight />
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';
import CallApi from '@/components/CallApi.vue';
import confirmDialog from '@/components/ConfirmDialog.vue';
import createDownloadFileDialog from '@/views/u-04/dialog/CreateDownloadFileDialog.vue';

export default {
  components: {
    CopyRight,
    CallApi,
    createDownloadFileDialog,
    confirmDialog,
  },
  props: {
    selectedItems: {
      type: Array,
      required: true,
    },
    use_cleansing_immediate: {
      type: Boolean,
      required: true,
    },
    output_layout_list: {
      type: Array,
      required: true,
    },
  },
  mounted() {
  },

  data: () => ({
    nightly_disable_status: ['取込異常終了', '待機中', 'クレンジング中', 'クレンジング異常終了', 'ファイル作成中', 'ファイル作成異常終了'],
    cancel_disable_status: ['取込中', '取込済', '取込異常終了', 'クレンジング中', 'クレンジング異常終了', 'クレンジング済', 'ファイル作成中', 'ファイル作成異常終了', 'ファイル作成済', 'ダウンロード済'],
    immediate_disable_status: ['取込中', '取込異常終了', 'クレンジング中', 'クレンジング異常終了', 'ファイル作成中', 'ファイル作成異常終了'],
    create_file_disable_status: ['取込中', '取込済', '取込異常終了', '待機中', 'クレンジング中', 'クレンジング異常終了', 'ファイル作成中', 'ファイル作成異常終了', 'ファイル作成済', 'ダウンロード済'],
    download_disable_status: ['取込中', '取込済', '取込異常終了', '待機中', 'クレンジング中', 'クレンジング異常終了', 'クレンジング済', 'ファイル作成中', 'ファイル作成異常終了'],
    delete_disable_status: ['取込中', 'クレンジング中', 'ファイル作成中'],

    // ダウンロードファイル作成ダイアログのパラメータ
    selected_ouput_layout_id: null,
    exclude_error: false,
    exclude_unmatch_item: false,
    exclude_unmatch_store: false,

    presigned_url_list: [],
  }),

  methods: {
    // クレジング夜間実行ボタン押下時
    cleansing_nightily_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // ファイル作成ダイアログを開く
      this.$refs.createDownloadFileDialog.open(
        'クレンジング夜間実行',
        () => {
        // 「作成」の場合
        // 夜間実行処理を行う
          this.cleansing_nightly_execution();
        },
        () => {
        // 「キャンセル」の場合は何もしない
        },
      );
    },
    cleansing_nightly_execution() {
      // 子コンポーネントから入力値を受け取る
      this.getChildParam();
      this.$refs.CallApi.callApi(
        'api/u-04/cleansing_nightly_execution',
        {
          id_list: this.selectedItems.map((item) => item.id),
          output_layout_id: this.selected_ouput_layout_id,
          exclude_error: this.exclude_error,
          exclude_unmatch_item: this.exclude_unmatch_item,
          exclude_unmatch_store: this.exclude_unmatch_store,
        },
        () => {
        // APIが正常終了した場合
          this.$refs.createDownloadFileDialog.close();
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // クレジングキャンセルボタン押下時
    cleansing_cancel_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // 確認ダイアログを開く
      this.$refs.confirmDialog.open(
        'クレンジング夜間実行をキャンセルしますか？',
        () => {
        // 「はい」の場合
        // キャンセル処理を行う
          this.cancel_cleansing();
        },
        () => {
        // 「いいえ」の場合は何もしない

        },
      );
    },
    cancel_cleansing() {
      this.$refs.CallApi.callApi(
        'api/u-04/cleansing_nightly_execution_cancel',
        {
          id_list: this.selectedItems.map((item) => item.id),
        },
        () => {
        // APIが正常終了した場合
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // クレジング即時実行ボタン押下時
    cleansing_immediate_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // ファイル作成ダイアログを開く
      this.$refs.createDownloadFileDialog.open(
        'クレンジング即時実行',
        () => {
        // 「作成」の場合
        // 即時実行処理を行う
          this.cleansing_immediate_execution();
        },
        () => {
        // 「キャンセル」の場合は何もしない
        },
      );
    },
    cleansing_immediate_execution() {
      // 子コンポーネントから入力値を受け取る
      this.getChildParam();
      this.$refs.CallApi.callApi(
        'api/u-04/cleansing_immediate_execution',
        {
          id_list: this.selectedItems.map((item) => item.id),
          output_layout_id: this.selected_ouput_layout_id,
          exclude_error: this.exclude_error,
          exclude_unmatch_item: this.exclude_unmatch_item,
          exclude_unmatch_store: this.exclude_unmatch_store,
        },
        () => {
        // APIが正常終了した場合
          this.$refs.createDownloadFileDialog.close();
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // ファイル作成ボタン押下時
    create_file_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // 確認ダイアログを開く
      this.$refs.confirmDialog.open(
        'ダウンロードファイルを作成しますか？',
        () => {
        // 「はい」の場合
        // ダウンロードファイル作成処理を行う
          this.create_download_file();
        },
        () => {
        // 「いいえ」の場合は何もしない

        },
        // アイコンを表示しない
        false,
      );
    },
    create_download_file() {
      // 子コンポーネントから入力値を受け取る
      this.getChildParam();
      this.$refs.CallApi.callApi(
        'api/u-04/create_download_file',
        {
          id_list: this.selectedItems.map((item) => item.id),
        },
        () => {
        // APIが正常終了した場合
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // 一括ダウンロードボタン押下時
    download_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // 子コンポーネントから入力値を受け取る
      this.getChildParam();
      this.$refs.CallApi.callApi(
        'api/u-04/get_presigned_url',
        {
          id_list: this.selectedItems.map((item) => item.id),
        },
        (response) => {
        // APIが正常終了した場合
          this.$refs.createDownloadFileDialog.close();
          this.presigned_url_list = response.data.presigned_url_list;

          this.presigned_url_list.forEach((url) => {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            const evt = document.createEvent('MouseEvents');
            evt.initEvent('click', false, true);
            a.dispatchEvent(evt);
          });

          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    // 履歴削除ボタン押下時
    delete_click() {
      // 行が選択されていない場合は何もしない
      if (this.selectedItems.length === 0) {
        return;
      }
      // 確認ダイアログを開く
      this.$refs.confirmDialog.open(
        '選択した履歴を削除しますか？',
        () => {
        // 「はい」の場合
        // ダウンロード削除処理を行う
          this.delete_history();
        },
        () => {
        // 「いいえ」の場合は何もしない

        },
      );
    },
    delete_history() {
      // 子コンポーネントから入力値を受け取る
      this.getChildParam();
      this.$refs.CallApi.callApi(
        'api/u-04/delete_history',
        {
          id_list: this.selectedItems.map((item) => item.id),
        },
        () => {
        // APIが正常終了した場合
          this.$emit('search');
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },

    getChildParam() {
      // 検索条件を子コンポーネントから受け取る
      const inputList = this.$refs.createDownloadFileDialog.send_param_parent();
      const [
        selectedOuputLayoutId,
        excludeError,
        excludeUnmatchItem,
        excludeUnmatchStore,
      ] = inputList;

      this.selected_ouput_layout_id = selectedOuputLayoutId;
      this.exclude_error = excludeError;
      this.exclude_unmatch_item = excludeUnmatchItem;
      this.exclude_unmatch_store = excludeUnmatchStore;
    },
  },

  computed: {
    // ボタンの非活性制御
    isDisabled_nightly() {
      return this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.nightly_disable_status.includes(item.status_cd),
      );
    },
    isDisabled_cancel() {
      return this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.cancel_disable_status.includes(item.status_cd),
      );
    },
    isDisabled_immediate() {
      const buttonCondition = this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.immediate_disable_status.includes(item.status_cd),
      );
      return buttonCondition;
    },

    isDisabled_create_file() {
      return this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.create_file_disable_status.includes(item.status_cd),
      );
    },
    isDisabled_download() {
      return this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.download_disable_status.includes(item.status_cd),
      );
    },
    isDisabled_delete() {
      return this.selectedItems.length === 0 || this.selectedItems.some(
        (item) => this.delete_disable_status.includes(item.status_cd),
      );
    },

  },

};
</script>
