<!--
    画面ID：
    画面名：共通：コピーライト
-->
<template>
  <v-row no-gutters>
    <v-col cols="12">
      <div style="text-align: center" class="mt-2 mb-n4">
        Copyright(C){{ currentYear }} PLANET.INC.ALL Rights Reserved.<br />
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
