<!--
    画面ID：DialogParts
    画面名：u-04 ダイアログ部分
-->
<template>
  <!-- 詳細ダイアログ定義 -->
  <v-dialog
    v-model="dialog"
    v-bind:max-width="width"
    v-bind:min-width="width"
    v-bind:max-height="height"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">
          詳細画面
          <v-icon class="ml-1 mb-1">mdi-information-outline</v-icon>
        </span>
      </v-card-title>
      <v-divider />
      <v-card-text v-bind:style="{ height: height + 'px' }">
        <!-- ここにコンポーネントを埋め込む -->
        <slot />
      </v-card-text>
      <v-divider class=""></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          height="50"
          color="#1C84AC"
          class=""
          @click="no_Click"
          elevation="1"
          v-bind:style="buttonStyle"
          outlined
        >
          <h3 class="font-weight-bold">閉じる</h3>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    width: 0,
    height: 0,
    dialog: false,
  }),

  methods: {
    // ダイアログを開く
    open(width, height, yesCallback, noCallback) {
      this.width = width;
      this.height = height;
      this.dialog = true;
      this.yesCallback = yesCallback;
      this.noCallback = noCallback;
    },
    no_Click() {
      this.dialog = false;
      if (this.noCallback) {
        this.noCallback();
      }
    },
  },
};
</script>
