<!--
    画面ID：DialogParts
    画面名：u-10 アップロード完了ダイアログ
-->
<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card>
        <v-toolbar color="grey darken-2" dark>
          <div class="text-h6 pa-5">アップロード完了</div>
        </v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">アップロードが完了しました。</div>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row justify="center" class="mx-8">
            <v-btn
              color="blue-grey lighten-1"
              large
              dark
              class="mx-auto mr-2"
              width="30%"
              height="50"
              @click="dialog = !dialog"
            >
              <h3>閉じる</h3>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),

  methods: {
    // ダイアログを開く
    open() {
      this.dialog = true;
    },
  },
};
</script>
