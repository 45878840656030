<!--
    画面名：ユーザー管理画面
-->
<template>
  <div>
    <!-- ダイアログ -->
    <userRegisterDialog v-on:search="search" ref="userRegisterDialog" />
    <v-footer
      app
      fixed
      :padless="true"
      :inset="true"
      color="blue-grey lighten-4"
      class="pa-2 pl-10"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-btn
            height="80"
            elevation="2"
            color=""
            v-bind="attrs"
            v-on="on"
            style="border: thin solid #1c84ac"
            @click="register_Click()"
          >
            <v-icon size="30" class="mr-3" color="#1C84AC"
              >mdi-account-plus</v-icon
            >
            <h2 style="color: #1c84ac">新規登録</h2>
          </v-btn>

          <v-btn
            height="80"
            elevation="2"
            color=""
            class="ml-5"
            style="border: thin solid #1c84ac"
            @click="remove_Click()"
            :disabled="!table_item_selected"
          >
            <v-icon size="30" class="mr-3" color="#1C84AC">mdi-delete</v-icon>
            <h2 style="color: #1c84ac">削除</h2>
          </v-btn>
        </v-row>
        <CopyRight />
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';
import userRegisterDialog from '@/views/u-12/userRegisterDialog.vue';

export default {
  components: {
    userRegisterDialog,
    CopyRight,
  },
  props: {
    table_item_selected: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({

  }),
  methods: {
    register_Click() {
      this.$emit('register_open');
    },
    remove_Click() {
      this.$emit('remove');
    },
  },
};
</script>
