<!--
    画面ID：searchParts
    画面名：u-04 検索条件欄の中の部品
-->
<template>
  <div>
    <v-container no-gutters fluid>
      <v-row class="my-8" no-gutters>
        <!-- 取込日 -->
        <v-col cols="2" class="mt-3 mr-n8"> <h3>取込日</h3> </v-col>
        <v-col cols="2">
          <customDatePicker
            label=""
            :date="upload_at_from"
            :isLimit="true"
            :history_valid_days="history_valid_days"
            @update:date="update_upload_at_from"
          />
        </v-col>
        <v-col cols="1" align="center" justify="center" class="mt-3">
          <h3>～</h3>
        </v-col>
        <v-col cols="2">
          <customDatePicker
            label=""
            :date="upload_at_to"
            :isLimit="true"
            :history_valid_days="history_valid_days"
            @update:date="update_upload_at_to"
          />
        </v-col>

        <v-col cols="1" />

        <!-- レイアウト名 -->
        <v-col cols="2" class="mt-3 mx-n10">
          <h3>レイアウト名</h3>
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="selected_layout_id_list"
            label=""
            :items="input_layout_list_copy"
            item-text="layout_nm"
            item-value="id"
            background-color="white"
            outlined
            color="#1C84AC"
            multiple
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="mt-n10" no-gutters>
        <!-- クレンジング日 -->
        <v-col cols="2" class="mt-3 mr-n8"> <h3>クレンジング日</h3> </v-col>
        <v-col cols="2">
          <customDatePicker
            label=""
            :date="cleansing_at_from"
            :isLimit="true"
            :history_valid_days="history_valid_days"
            @update:date="update_cleansing_at_from"
          />
        </v-col>
        <v-col cols="1" align="center" justify="center" class="mt-3">
          <h3>～</h3>
        </v-col>
        <v-col cols="2">
          <customDatePicker
            label=""
            :date="cleansing_at_to"
            :isLimit="true"
            :history_valid_days="history_valid_days"
            @update:date="update_cleansing_at_to"
          />
        </v-col>

        <!-- ファイル名 -->
        <v-col cols="1" />
        <v-col cols="2" class="mt-3 mx-n10">
          <h3>ファイル名</h3>
        </v-col>
        <v-col cols="2">
          <v-text-field
            label=""
            background-color="white"
            outlined
            v-model="file_nm"
            maxlength="255"
            color="#1C84AC"
          />
        </v-col>
      </v-row>

      <v-row class="mt-n2" no-gutters>
        <!-- 状態 -->
        <v-col cols="2" class="mt-3 mr-n8"> <h3>状態</h3> </v-col>
        <v-col cols="2">
          <v-select
            label=""
            :items="status_list_copy"
            background-color="white"
            outlined
            v-model="selected_status_cd_list"
            item-text="text"
            item-value="cd"
            color="#1C84AC"
            multiple
            chips
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="mt-n4" no-gutters>
        <!-- 表示行 -->
        <v-col cols="2" class="mr-n8">
          <h3 class="mt-5">表示行</h3>
        </v-col>
        <v-checkbox color="#1C84AC" v-model="match" />
        <p class="text-h7 mt-5 mr-8">データ整合</p>
        <v-checkbox color="#1C84AC" v-model="unmatch" />
        <p class="text-h7 mt-5 mr-8">データ不整合</p>
        <v-checkbox color="#1C84AC" v-model="over_valid" />
        <p class="text-h7 mt-5">期限切れ</p>

        <!-- 検索ボタン -->
        <v-col cols="4" />
        <v-col cols="1" class="">
          <v-btn
            outlined
            elevation="2"
            height="60"
            width="130"
            color="#1C84AC"
            dark
            @click="searchClick"
            class="mt-n2"
          >
            <v-icon size="30">mdi-magnify</v-icon>
            <h3>検索</h3>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import customDatePicker from '../../components/CustomDatePicker.vue';

export default {
  components: {
    customDatePicker,
  },
  props: {
    history_valid_days: {
      type: Number,
      required: true,
    },
    input_layout_list: {
      type: Array,
      required: true,
    },
    status_list: {
      type: Array,
      required: true,
    },
  },
  // 初期画面出力
  mounted() {
    // 選択肢がある項目は先頭に空白の選択肢を挿入する
    this.input_layout_list_copy = this.input_layout_list;
    this.status_list_copy = this.status_list;
    // this.input_layout_list_copy = [{ id: null, layout_nm: '' }, ...this.input_layout_list];
    // this.status_list_copy = ['', ...this.status_list];
  },

  data: () => ({
    input_layout_list_copy: [],
    status_list_copy: [],

    upload_at_from: null,
    upload_at_to: null,
    cleansing_at_from: null,
    cleansing_at_to: null,
    selected_status_cd_list: null,
    selected_layout_id_list: null,
    file_nm: '',
    match: true,
    unmatch: true,
    over_valid: true,

  }),

  methods: {
    searchClick() {
      this.$emit('search');
    },
    update_upload_at_from(date) {
      // ✕で日付消した場合に''になってしまうためnullに設定
      if (date === '') {
        this.upload_at_from = null;
      } else {
        this.upload_at_from = date;
      }
    },
    update_upload_at_to(date) {
      if (date === '') {
        this.upload_at_to = null;
      } else {
        this.upload_at_to = date;
      }
    },
    update_cleansing_at_from(date) {
      if (date === '') {
        this.cleansing_at_from = null;
      } else {
        this.cleansing_at_from = date;
      }
    },
    update_cleansing_at_to(date) {
      if (date === '') {
        this.cleansing_at_to = null;
      } else {
        this.cleansing_at_to = date;
      }
    },

    send_param_parent() {
      // 親コンポーネントに検索条件を渡す(親から呼ぶ)
      const searchList = [];
      searchList.push(this.upload_at_from);
      searchList.push(this.upload_at_to);
      searchList.push(this.cleansing_at_from);
      searchList.push(this.cleansing_at_to);
      searchList.push(this.selected_status_cd_list);
      searchList.push(this.selected_layout_id_list);
      searchList.push(this.file_nm);
      searchList.push(this.match);
      searchList.push(this.unmatch);
      searchList.push(this.over_valid);
      this.$emit('GetSearchParam', searchList);
    },
  },

};
</script>
