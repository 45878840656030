<!--
    画面ID：FooterParts
    画面名：u-10 底部の部品
-->
<template>
  <div>
    <v-footer
      app
      fixed
      :padless="true"
      :inset="true"
      color="blue-grey lighten-4"
      class="pa-2"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <div>
              <v-btn
                height="80"
                color=""
                size="x-large"
                v-bind="attrs"
                v-on="on"
                elevation="1"
                @click="upload_Click"
                style="border: thin solid #1c84ac"
              >
                <v-icon size="30" class="" color="#1C84AC">mdi-upload</v-icon>
                <h2 style="color: #1c84ac">アップロード</h2>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <CopyRight />
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';

export default {
  components: {
    CopyRight,
  },
  data: () => ({

  }),
  methods: {
    upload_Click() {
      this.$emit('get_presigned_url');
    },
  },
};
</script>
