<!--
    画面ID：Api
    画面名：共通処理：APIのコール処理
-->
<template>
  <div>
    <ErrorDialog ref="ErrorDialog"></ErrorDialog>
    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-text>
          <v-row justify="center" class="pt-13 mb-2">
            <v-icon x-large color="">mdi-gamepad-circle-outline</v-icon>
          </v-row>
          <v-row justify="center">
            <div class="text-h5 px-12 py-6">しばらくお待ち下さい...</div>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-progress-linear indeterminate striped height="10" color="grey"></v-progress-linear>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import ErrorDialog from '@/components/ErrorDialog.vue';

export default {
  components: {
    ErrorDialog,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    async callApi(url, userParams, callbackNormal, callbackError, options = { showError: true }) {
      // ダイアログを表示する
      this.dialog = true;

      // アクセストークンの再発行が発生する場合、APIは最大2回コールする
      try {
        for (let i = 0; i < 2; i += 1) {
          try {
            // アクセストークン付きでAPIを呼び出す
            // eslint-disable-next-line no-await-in-loop
            const response = await axios.post(url, userParams, { headers: { Authorization: `Bearer ${localStorage.accessToken}` } });
            if (response.data.err === '') {
              callbackNormal(response);
            } else if (options.showError) {
              this.$refs.ErrorDialog.open(response.data.errmsg, () => {
                if (response.data.err === 'system_closed') {
                  this.$router.push({ name: 'u-01' });
                } else {
                  callbackError(response);
                }
              });
            }
            // 例外が発生しない場合(ステータスコードが200)の場合はループを抜ける
            break;
            // ステータスコードが200以外
          } catch (error) {
            // アクセス権がない場合はログイン画面に遷移する
            if (error.response.data.err === 'forbidden') {
              if (this.$route.name !== 'u-01') {
                this.$router.push({ name: 'u-01' });
              }
            // アクセストークンの有効期限切れの場合、リフレッシュトークンで再ログインする
            } else if (error.response.data.err === 'invalid_token') {
              try {
                // eslint-disable-next-line no-await-in-loop
                const response2 = await axios.post('/api/u-01/login', { refresh: true }, { headers: { Authorization: `Bearer ${localStorage.refreshToken}` } });
                if (response2.data.err === '') {
                  localStorage.accessToken = response2.data.accessToken;
                  localStorage.refreshToken = response2.data.refreshToken;
                } else {
                  localStorage.accessToken = null;
                  localStorage.refreshToken = null;
                }
              } catch (error2) {
                if (error2.response.data.err) {
                  if (options.showError) {
                    this.$refs.ErrorDialog.open(error2.response.data.errmsg, () => {
                      this.$router.push({ name: 'u-01' });
                    });
                  }
                  break;
                } else {
                  if (options.showError) {
                    this.$refs.ErrorDialog.open('システムエラーが発生しました', () => {
                      this.$router.push({ name: 'u-01' });
                    });
                  }
                  break;
                }
              }
            } else if (error.response.data.err) {
              if (options.showError) {
                this.$refs.ErrorDialog.open(error.response.data.errmsg, () => {
                  this.$router.push({ name: 'u-01' });
                });
              }
              break;
            } else {
              if (options.showError) {
                this.$refs.ErrorDialog.open('システムエラーが発生しました', () => {
                  this.$router.push({ name: 'u-01' });
                });
              }
              break;
            }
          }
        }
      } finally {
        this.dialog = false;
      }
    },

  },
};
</script>
