<!--
    画面名：トップ画面
-->
<template>
  <v-app>
    <CallApi ref="CallApi" />
    <v-container
      fluid
      style="padding-top: 150px; max-width: 1600px; min-width: 1600px"
    >
      <v-row justify="center" no-gutters>
        <v-col cols="7">
          <v-row justify="center">
            <v-col cols="12">
              <v-btn
                height="340"
                width="48%"
                outlined
                @click="upload_click"
                elevation="3"
                style="color: #1c84ac; margin-right: 20px"
                :disabled="!role.u03"
              >
                <v-col class="" cols="4" style="height: 320px">
                  <v-row justify="center" align="center" class="mt-15">
                    <v-icon size="140" class="mt-5" color="">mdi-upload</v-icon>
                  </v-row>
                </v-col>
                <v-col cols="8">
                  <h1 style="font-size: 38px">
                    データ
                    <br />
                    アップロード
                  </h1>
                </v-col>
              </v-btn>

              <v-btn
                height="340"
                width="48%"
                style="color: #1c84ac"
                @click="history_click"
                outlined
                elevation="3"
                :disabled="!role.u04"
              >
                <v-col class="" cols="4" style="height: 320px">
                  <v-row justify="center" align="center" class="mt-15">
                    <v-icon size="130" class="mt-5 mr-n13" color=""
                      >mdi-text-box-search</v-icon
                    >
                  </v-row>
                </v-col>
                <v-col cols="8">
                  <h1 style="font-size: 38px" class="mr-2">履歴検索</h1>
                </v-col>
              </v-btn>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12">
              <v-btn
                height="227"
                width="31.5%"
                style="color: #1c84ac; margin-right: 20px"
                @click="item_master_click"
                outlined
                elevation="3"
                :disabled="!role.u10 || !use_maker_master_item"
              >
                <v-col class="" cols="4" style="height: 208px">
                  <v-icon size="70" class="mt-13" color="">icon-shampoo</v-icon>
                </v-col>
                <v-col cols="8">
                  <h2 style="font-size: 35px">
                    商品
                    <br />
                    マスタ
                  </h2>
                </v-col>
              </v-btn>

              <v-btn
                height="227"
                width="31.2%"
                style="color: #1c84ac; margin-right: 20px"
                outlined
                elevation="3"
                @click="store_master_click"
                :disabled="!role.u11 || !use_maker_master_store"
              >
                <v-col class="" cols="4" style="height: 208px">
                  <v-icon size="80" class="mt-13" color="">mdi-store</v-icon>
                </v-col>
                <v-col cols="8">
                  <h2 style="font-size: 35px">
                    店舗
                    <br />
                    マスタ
                  </h2>
                </v-col>
              </v-btn>

              <v-btn
                height="227"
                width="31.2%"
                style="color: #1c84ac"
                outlined
                elevation="3"
                @click="manage_user_click"
                :disabled="!role.u12"
              >
                <v-col class="" cols="4" style="height: 208px">
                  <v-icon size="80" class="mt-13" color=""
                    >mdi-account-cog</v-icon
                  >
                </v-col>
                <v-col cols="8">
                  <h2 style="font-size: 35px">
                    ユーザー
                    <br />
                    管理
                  </h2>
                </v-col>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- お知らせ -->
        <v-col cols="5">
          <v-card
            width="100%"
            height="433px"
            class=""
            style="border: thin solid #1c84ac"
          >
            <!-- お知らせの中身 -->
            <v-card-text
              class="blue-grey--text pt-0 mt-4"
              :style="{ 'overflow-y': 'auto', height: '95%' }"
            >
              <div class="sticky-header white" style="position: sticky; top: 0">
                <v-row class="ml-3 pt-0 mb-2" no-gutters>
                  <h2 class="">Infomation</h2>
                  <v-icon color="blue-grey" class="ml-2">mdi-bell</v-icon>
                </v-row>
                <v-divider class="mt-3" />
              </div>

              <div v-for="(news, index) in news_list" :key="index">
                <v-card-title class="">
                  <v-col cols="4">
                    <span style="font-size: 18px">{{ news.start_at }}</span>
                  </v-col>
                  <v-col cols="8">{{ news.subject }} </v-col>
                </v-card-title>
                <v-row>
                  <v-col cols="1" class="" />
                  <v-col cols="3" class="">
                    <v-row class="mt-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            small
                            elevation="2"
                            style="
                              border: thin solid #1c84ac;
                              height: 35px;
                              width: 35px;
                            "
                            v-if="news.link_uri"
                            @click="openLink(news)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="25" color="#1C84AC">
                              mdi-link-variant
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>リンクを開く</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            fab
                            small
                            class="ml-2"
                            elevation="2"
                            style="
                              border: thin solid #1c84ac;
                              height: 35px;
                              width: 35px;
                            "
                            v-if="news.file_nm"
                            @click="downloadFile(news)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon size="25" color="#1C84AC">
                              mdi-paperclip
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>添付ファイルダウンロード</span>
                      </v-tooltip>
                    </v-row></v-col
                  >
                  <v-col cols="8">
                    <span style="white-space: pre-wrap" v-html="news.body" />
                  </v-col>
                </v-row>

                <v-divider class="mt-5" />
              </div>
            </v-card-text>
          </v-card>

          <!-- お問い合わせ -->
          <v-card
            width="100%"
            height="150px"
            class="mx-auto mt-2 lighten-4"
            color=""
            style="border: thin solid #1c84ac"
          >
            <v-card-text class="blue-grey--text" v-html="inquiry">
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <CopyRight />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import CopyRight from '@/components/CopyRight.vue';
import CallApi from '../../components/CallApi.vue';

export default {
  components: {
    CopyRight,
    CallApi,
  },
  data: () => ({
    role: {},
    use_maker_master_item: false,
    use_maker_master_store: false,
    inquiry: '',
    news_list: [],
    file_base64: '',
  }),
  mounted() {
    this.$refs.CallApi.callApi(
      '/api/u-02/get_initial_info',
      {},
      (response) => {
        // APIが正常終了した場合
        this.role = response.data.role;
        this.use_maker_master_item = response.data.use_maker_master_item;
        this.use_maker_master_store = response.data.use_maker_master_store;
        this.inquiry = response.data.inquiry;
        this.news_list = response.data.news_list.map((newsItem) => ({
          ...newsItem,
          start_at: this.formatDatetime(newsItem.start_at),
          stop_at: this.formatDatetime(newsItem.stop_at),
        }));
      },
      () => {
      // エラーハンドリングは共通処理
      },
    );
  },
  methods: {
    upload_click() {
      this.$router.push({ name: 'u-03' });
    },
    history_click() {
      this.$router.push({ name: 'u-04' });
    },
    item_master_click() {
      this.$router.push({ name: 'u-10' });
    },
    store_master_click() {
      this.$router.push({ name: 'u-11' });
    },
    manage_user_click() {
      this.$router.push({ name: 'u-12' });
    },

    openLink(news) {
      // リンクを開く
      window.open(news.link_uri, '_blank');
    },

    // 添付ファイルダウンロードボタン押下時
    async downloadFile(news) {
      this.$refs.CallApi.callApi(
        'api/u-02/download',
        {
          id: news.id,
        },
        (response) => {
          this.file_base64 = response.data.file_base64;
          this.start_download(news);
        },
        () => {
          // APIでエラーが発生した場合
        },
      );
    },
    async start_download(news) {
      // base64データをBlobオブジェクトに変換する
      const byteCharacters = atob(this.file_base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/octet-stream' });

      // BlobオブジェクトをURLに変換する
      const url = URL.createObjectURL(blob);

      // ダウンロードリンクを作成する
      const link = document.createElement('a');
      link.href = url;
      link.download = news.file_nm; // ファイル名を指定する
      document.body.appendChild(link);

      // ダウンロードを開始する
      link.click();

      // 不要になったURLオブジェクトを解放する
      URL.revokeObjectURL(url);
    },

    // datetimeを文字列に変換する
    formatDatetime(datetime) {
      const date = new Date(datetime);
      return date.toLocaleString('ja-JP', {
        year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false,
      });
    },

  },
};
</script>
