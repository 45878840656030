<!--
    画面名：ファイル内重複確認画面
-->
<template>
  <v-row class="ml-n3 mt-5">
    <v-col class="pa-0">
      <v-row align="start" no-gutters class="">
        <v-col cols="2"> <h3>取込番号</h3> </v-col>
        <v-col cols="5">
          <h3>{{ upload_no }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3">
        <v-col cols="2"> <h3>取込日付</h3> </v-col>
        <v-col cols="5">
          <h3>{{ upload_at }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3">
        <v-col cols="2"> <h3>小売ID</h3> </v-col>
        <v-col cols="5">
          <h3>{{ retail_id }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3">
        <v-col cols="2"> <h3>レイアウト名</h3> </v-col>
        <v-col cols="5">
          <h3>{{ layout_nm }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3">
        <v-col cols="2"> <h3>ファイル名</h3> </v-col>
        <v-col cols="5">
          <h3>{{ file_nm }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3">
        <v-col cols="2"> <h3>取込件数</h3> </v-col>
        <v-col cols="5">
          <h3>{{ input_count }}</h3>
        </v-col>
      </v-row>
      <v-row align="start" no-gutters class="mt-3 mb-3">
        <v-col cols="2"> <h3>重複件数</h3> </v-col>
        <v-col cols="5">
          <h3>{{ duplicate_count }}</h3>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    upload_no: {
      type: String,
      required: true,
    },
    upload_at: {
      type: String,
      required: true,
    },
    retail_id: {
      type: String,
      required: true,
    },
    layout_nm: {
      type: String,
      required: true,
    },
    file_nm: {
      type: String,
      required: true,
    },
    input_count: {
      type: Number,
      required: true,
    },
    duplicate_count: {
      type: Number,
      required: true,
    },
  },
  components: {
  },

  data: () => ({

  }),
};
</script>
