<!--
    画面名：商品マスタ画面
-->
<template>
  <div>
    <CallApi ref="CallApi"></CallApi>
    <uploadCompleteDialog ref="uploadCompleteDialog"></uploadCompleteDialog>
    <!-- ダイアログ定義 -->
    <uploadingDialog
      ref="uploadingDialog"
      v-on:call_get_initial_info="call_get_initial_info"
    />

    <!-- 底部 -->
    <footerParts v-on:get_presigned_url="get_presigned_url" />

    <v-main class="grey lighten-3 pa-0 align-start">
      <v-container fluid class="">
        <v-card class="mt-5 mx-5 pb-8 grey lighten-5" width="">
          <v-card-title class="mb-8"><h3>店舗マスタ画面</h3></v-card-title>
          <v-row justify="start" class="mx-13">
            <v-col cols="3" class="mt-3 mr-n10">
              <h2>ファイルを選択</h2>
            </v-col>
            <v-col cols="6">
              <v-file-input
                truncate-length="50"
                accept=".xlsx, .xls"
                v-model="selected_file"
                label="ファイル"
                class=""
                outlined
                background-color="white"
                color="#1C84AC"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n7" v-if="file_nm">
            <v-col cols="3" />
            <v-col cols="">
              <v-row class="mt-n5">
                <v-col cols="">
                  <div>
                    以下のファイルが登録済みです。<br />
                    <h4>{{ file_nm }}</h4>
                    <br />
                    ファイル内容を変更する場合は、ダウンロードしたファイルを編集してアップロードしてください。
                  </div>
                  <v-btn
                    class="mt-1"
                    outlined
                    color="#1C84AC"
                    elevation="1"
                    @click="downloadFile"
                  >
                    登録済みファイルダウンロード
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>

import CallApi from '@/components/CallApi.vue';
import uploadCompleteDialog from '@/components/UploadCompleteDialog.vue';
import uploadingDialog from '@/components/UploadingDialog.vue';
import footerParts from './FooterParts.vue';

export default {
  components: {
    footerParts,
    uploadingDialog,
    CallApi,
    uploadCompleteDialog,
  },

  data: () => ({
    selected_file: null,
    file_nm: '',
    presigned_url_upload: '',
    presigned_url_download: '',
  }),
  // 初期画面出力
  mounted() {
    this.call_get_initial_info();
  },

  methods: {
    async get_presigned_url() {
      this.$refs.CallApi.callApi(
        'api/u-11/get_presigned_url_upload',
        {
          upload_file_size: this.selected_file ? this.selected_file.size : 0,
          store_master_file_nm: this.selected_file ? this.selected_file.name : '',
        },
        (response) => {
        // APIが正常終了した場合
          this.presigned_url_upload = response.data.presigned_url_upload;
          this.upload();
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
    upload() {
      this.$refs.uploadingDialog.open(
        this.presigned_url_upload,
        this.selected_file,
        () => {
        // 署名付きURLに対してのアップロード処理が正常終了した場合
          this.register_bq(this.selected_file.name);
          this.$refs.uploadingDialog.close();
          this.selected_file = null;
        },
        () => {
          // this.$refs.uploadingDialog.close();
          this.selected_file = null;
        },
      );
    },
    register_bq(fileNm) {
      this.$refs.CallApi.callApi(
        'api/u-11/register_store_master',
        {
          store_master_file_nm: fileNm,
        },
        () => {
          // APIが正常終了した場合
          this.$refs.uploadCompleteDialog.open();
          // 画面更新
          this.call_get_initial_info();
        },
        () => {
          // エラーが発生した場合
        },
      );
    },
    downloadFile() {
      this.$refs.CallApi.callApi(
        'api/u-11/get_presigned_url_download',
        {

        },
        (response) => {
        // APIが正常終了した場合
          this.presigned_url_download = response.data.presigned_url_download;
          window.location.href = this.presigned_url_download;
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
    call_get_initial_info() {
    // API「get_initial_info」を呼び、一覧を得る。
      this.$refs.CallApi.callApi(
        'api/u-11/get_initial_info',
        {},
        (response) => {
        // APIが正常終了した場合
          this.file_nm = response.data.file_nm;
        },
        () => {
        // ・APIが異常終了した場合
        // ・処理終了。（エラーハンドリングは共通処理）
        },
      );
    },
  },
};
</script>
